import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import './assets/style.css';
import CosmoDbDetail from './CosmoDbDetail';
import CosmoDbList from './CosmoDbList';
import CosmoDbContext from './lib/CosmoDbContext';
import CosmoDbUtils from './lib/CosmoDbUtils';

interface Props {
    lang: MultiLang;
    modulepath: string;
}

interface State {
    loading: boolean;
    context: CosmoDbContext | null;
}

class CosmoDb extends Component<Props, State> {

    private isActive: boolean;

    public constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            context: null,
        };
    }

    public componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, modulepath } = this.props;
        if (prevProps.lang !== lang || prevProps.modulepath !== modulepath) {
            this.updatePage();
        }
    }

    public componentWillUnmount() {
        this.isActive = false;
    }

    public async updatePage() {
        const { modulepath } = this.props;
        const context = CosmoDbUtils.getContext(modulepath);
        const status = await context.initialize();
        if (this.isActive) {
            this.setState({ loading: false, context: status ? context : null });
        }
    }

    public render() {
        const { lang, modulepath } = this.props;
        const { loading, context } = this.state;
        const topUrl = modulepath + '/list.php?id=1';
        if (loading) {
            return <Loading />;
        }
        if (context === null) {
            return <PageNotFound lang={lang} />;
        }
        return <div className="cosmodb">
            <Switch>
                <Route exact path={modulepath} render={(props: RouteComponentProps) => {
                    return <Redirect to={topUrl} />
                }} />
                <Route exact path={`${modulepath}/index.php`} render={(props: RouteComponentProps) => {
                    return <Redirect to={topUrl} />
                }} />
                <Route path={`${modulepath}/list.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    return <CosmoDbList lang={lang} context={context} params={params} />;
                }} />
                <Route path={`${modulepath}/detail.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const idStr = params.get('id') || '';
                    const tabStr = params.get('tab') || '1';
                    if (!/^\d+/.test(idStr) || !/^\d+/.test(tabStr)) {
                        return <PageNotFound lang={lang} />;
                    }
                    const id = parseInt(idStr);
                    const tab = parseInt(tabStr);
                    return <CosmoDbDetail lang={lang} context={context} id={id} tab={tab} />;
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </div>;
    }
}

export default CosmoDb;
