import axios from 'axios';
import loki from 'lokijs';
import XRegExp from 'xregexp';
import Functions from '../../functions';

export enum CosmoDbComponentDataType {
    System = 1,
    RadioButtom,
    CheckBox,
    Text,
    Select
}

export interface CosmoDbComponentData {
    comp_id: number;
    tag: string;
    name: string;
    exp: string;
    type: CosmoDbComponentDataType;
    default_value: string;
    select_value: string;
    onoff: number; // use for sort, 0: yes, 1: no
    sort: number; // sort order
    nonull: number; // required field
    textmax: number; // 0 means textarea
    onoff_refine: number; // use for refine, 0: yes, 1: no
}

export interface CosmoDbKeywordData {
    kw_id: number;
    keyword: string;
    sort: number;
    pid: number;
}

export enum CosmoDbListDataType {
    List = 1,
    Thumbnail
}

export interface CosmoDbListData {
    list_id: number;
    name: string;
    type: CosmoDbListDataType;
    list_th: string;
    thumb_dir: string;
    thumb_size: string;
    template: string;
}

export interface CosmoDbDetailItemData {
    type: string;
    name: string;
    path: string;
    reg_date: number;
    reg_user: string;
}

export interface CosmoDbBasicData {
    id: number;
    label: string;
    reg_date: number;
    author: string;
    views: number;
    components: {
        name: string;
        value: string;
    }[];
    thumbnails: {
        url: string;
        caption: string;
    }[];
    items: CosmoDbDetailItemData[];
}

export interface CosmoDbIndexData {
    component: CosmoDbComponentData[];
    keyword: CosmoDbKeywordData[];
    detail: string;
    list: CosmoDbListData[];
    data: CosmoDbBasicData[];
}

export interface CosmoDbDetailCommentData {
    subject: string;
    message: string;
    reg_date: number;
    reg_user: string;
}

export interface CosmoDbDetailCommentTopicData {
    topic_id: number;
    com_id: number;
    type: string;
    comment: CosmoDbDetailCommentData;
    replies: CosmoDbDetailCommentData[];
}

export interface CosmoDbDetailLinkData {
    type: number;
    user: string,
    name: string;
    href: string;
    note: string;
}

export interface CosmoDbDetailData {
    id: number;
    users: string[];
    keywords: number[];
    comment_topics: CosmoDbDetailCommentTopicData[];
    links: CosmoDbDetailLinkData[];
}

export interface CosmoDbBasicSearchResult {
    total: number;
    result: CosmoDbBasicData[];
}

class CosmoDbContext {

    private isInitialized: boolean;
    private modulepath: string;
    private database: loki;
    private component: Collection<CosmoDbComponentData>;
    private keyword: Collection<CosmoDbKeywordData>;
    private detailTemplate: string | null;
    private list: Collection<CosmoDbListData>;
    private basic: Collection<CosmoDbBasicData>;

    public constructor(modulepath: string) {
        this.isInitialized = false;
        this.modulepath = modulepath;
        this.database = new loki(modulepath);
        this.component = this.database.addCollection('component');
        this.keyword = this.database.addCollection('keyword');
        this.detailTemplate = null;
        this.list = this.database.addCollection('list');
        this.basic = this.database.addCollection('data');
    }

    public getModuleName(): string {
        return this.modulepath.replace('/modules/', '');
    }

    public getUrl(path: string): string {
        return `${this.modulepath}/${path}`;
    }

    public getListUrl(params: URLSearchParams): string {
        return this.getUrl('list.php?' + params.toString());
    }

    public getDetailUrl(id: number, tab?: number): string {
        const tabParam = (typeof tab !== 'undefined' && tab !== 1) ? `&tab=${tab.toString()}` : ''
        return this.getUrl(`detail.php?id=${id}${tabParam}`);
    }

    public getThumbnailUrl(id: number, path: string): string {
        if (path === '') {
            return this.getUrl(`images/noimage.gif`);
        }
        return this.getUrl(`extract/${id}/thumbnail/${path}`);
    }

    public getDataUrl(id: number, path: string): string {
        return this.getUrl(`extract/${id}/data/${path}`);
    }

    public getBasic(id: number): CosmoDbBasicData | null {
        return this.basic.findOne({ id: id });
    }

    public getLists(): CosmoDbListData[] {
        return this.list.find();
    }

    public getList(id: number): CosmoDbListData | null {
        return this.list.findOne({ list_id: id });
    }

    public getComponents(): CosmoDbComponentData[] {
        return this.component.find();
    }

    public getComponent(id: number): CosmoDbComponentData | null {
        return this.component.findOne({ comp_id: id });
    }

    public renderBasic(basic: CosmoDbBasicData, template: string, linkToDetail: boolean): string {
        const components = this.component.find();
        const url = this.getDetailUrl(basic.id);
        let text = template;
        components.forEach((value) => {
            let replace = '';
            if (value.type === 1) {
                switch (value.name) {
                    case 'ID':
                        replace = basic.id.toString();
                        break;
                    case 'Data Name':
                        replace = basic.label;
                        break;
                    case 'Author':
                        replace = basic.author;
                        break;
                    case 'Creation Date':
                        replace = Functions.formatDate(basic.reg_date, 'YYYY-MM-DD');
                        break;
                    case 'Views':
                        replace = basic.views.toString();
                        break;
                }
            } else {
                const component = basic.components.find((c) => { return c.name === value.name; });
                replace = typeof component !== 'undefined' ? component.value : '';
            }
            replace = Functions.htmlspecialchars(replace);
            if (replace !== '' && linkToDetail) {
                replace = `<a href="${url}">` + replace + '</a>';
            }
            text = text.replace(`{${value.name}}`, replace);
        });
        let dirs = '';
        basic.items.forEach((value) => {
            if (value.type === 'dir' && value.path === '') {
                if (dirs !== '') {
                    dirs += ', ';
                }
                dirs += value.name.substr(0, 3);
            }
        })
        dirs = Functions.htmlspecialchars(dirs);
        if (dirs !== '' && linkToDetail) {
            dirs = `<a href="${url}">${dirs}</a>`;
        }
        text = text.replace(`{Dirs}`, dirs);
        return text;
    }

    public renderDetail(basic: CosmoDbBasicData, detail: CosmoDbDetailData, tab: number): string {
        let template = this.detailTemplate || '';
        return this.renderBasic(basic, template, false)
            .replace(/{(AddBookmark|AddLink|Config|FileManager)}/g, '')
            .replace('{Acomment}', this.renderDetailAuthorComment(detail))
            .replace('{Ucomment}', this.renderDetailUserComment(detail))
            .replace('{Keyword}', this.renderDetailKeywords(detail))
            .replace('{News}', this.renderDetailNews(basic))
            .replace('{Link}', this.renderDetailLinks(basic, detail))
            .replace(XRegExp('{Image\\s+(.*?)\\s+(.*?)\\s*}', 'g'), (whole, m1, m2) => this.renderDetailImage(basic, m1, m2))
            .replace('{Dtree}', this.renderDetailDataTree(basic))
            .replace(XRegExp('{href_tab(\\d+)}(.*?){/href_tab}', 'g'), (whole, m1, m2) => this.renderDetailHrefTab(basic, m1, m2))
            .replace(XRegExp('{tab(\\d+)}(.*?){/tab}', 'sg'), (whole, m1, m2) => this.renderDetailTab(tab, m1, m2));
    }

    public search(params: URLSearchParams): CosmoDbBasicSearchResult {
        const paramItem = params.get('item');
        const paramN = params.get('n');
        const paramSort = params.get('sort');
        const paramSortMethod = params.get('sort_method');
        const offset = (paramItem !== null && /^\d+/.test(paramItem)) ? parseInt(paramItem) : 0;
        const limit = (paramN !== null && /^\d+/.test(paramN)) ? parseInt(paramN) : 20;
        const sort = (paramSort !== null && /^\d+/.test(paramSort)) ? parseInt(paramSort) : 1;
        const sortMethod = (paramSortMethod !== null && paramSortMethod === 'asc') ? 'asc' : 'desc';
        const component = this.component.findOne({ comp_id: sort })
        const sortFunc = (a: CosmoDbBasicData, b: CosmoDbBasicData) => {
            if (component !== null) {
                if (component.type === CosmoDbComponentDataType.System) {
                    switch (component.name) {
                        case 'ID':
                            return a.id === b.id ? 0 : (a.id > b.id ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                        case 'Data Name':
                            return a.label === b.label ? 0 : (a.label > b.label ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                        case 'Author':
                            return a.author === b.author ? 0 : (a.author > b.author ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                        case 'Creation Date':
                            return a.reg_date === b.reg_date ? 0 : (a.reg_date > b.reg_date ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                        case 'Views':
                            return a.views === b.views ? 0 : (a.views > b.views ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                    }
                } else {
                    const ac = a.components.find((value) => { return value.name === component.name });
                    const bc = b.components.find((value) => { return value.name === component.name });
                    if (typeof ac !== 'undefined' && typeof bc !== 'undefined') {
                        return ac.value === bc.value ? 0 : (ac.value > bc.value ? (sortMethod === 'asc' ? 1 : -1) : (sortMethod === 'asc' ? -1 : 1));
                    }
                }
            }
            return 0;
        }
        const resultSet = this.basic.chain().find();
        return {
            total: resultSet.count(),
            result: resultSet.sort(sortFunc).offset(offset).limit(limit).data(),
        };
    }

    public async getDetail(contentId: number): Promise<CosmoDbDetailData | null> {
        let page: CosmoDbDetailData | null = null;
        try {
            const response = await axios.get(this.getUrl(`${contentId}.json`));
            page = response.data as CosmoDbDetailData;
        } catch (e) {
            // ignore
        }
        return page;
    }

    public async initialize(): Promise<boolean> {
        if (!this.isInitialized) {
            try {
                const response = await axios.get(this.getUrl('index.json'));
                const index = response.data as CosmoDbIndexData;
                index.component.forEach((value) => {
                    this.component.insert(value);
                });
                index.keyword.forEach((value) => {
                    this.keyword.insert(value);
                });
                this.detailTemplate = index.detail;
                index.list.forEach((value) => {
                    this.list.insert(value);
                })
                index.data.forEach((value) => {
                    this.basic.insert(value);
                })
            } catch (err) {
                // ignore
            }
            this.isInitialized = true;
        }
        return this.isInitialized;
    }

    private renderTextArea(text: string): string {
        const regexI = XRegExp('\\[i\\](.*?)\\[/i\\]', 'sg');
        const regexB = XRegExp('\\[b\\](.*?)\\[/b\\]', 'sg');
        text = text.replace(/\r?\n/g, '<br />')
            .replace(regexI, (whole, m1) => `<em>${m1}</em>`)
            .replace(regexB, (whole, m1) => `<strong>${m1}</strong>`);
        return text;
    }

    private renderComment(comment: CosmoDbDetailCommentData, showSubject: boolean) {
        const datetime = Functions.formatDate(comment.reg_date, 'YYYY-MM-DD HH:mm');
        const message = this.renderTextArea(comment.message);
        const subject = showSubject ? `<b>${this.renderTextArea(comment.subject)}</b><br />` : '';
        return `<tr>`
            + `<td class="even" style="text-align: center">${comment.reg_user}<br />${datetime.replace(' ', '<br />')}</td>`
            + `<td>${subject}${message}</td>`
            + `</tr>`;
    }

    private renderDetailAuthorComment(detail: CosmoDbDetailData): string {
        const topics = detail.comment_topics.find((value) => { return value.type === 'auth'; });
        let result = '';
        if (typeof topics !== 'undefined') {
            result = '<table class="list_table"><tbody>'
                + '<tr><th colspan="2">[en]Author Comment[/en][ja]オーサーコメント[/ja]</th></tr>'
                + this.renderComment(topics.comment, false)
                + topics.replies.map((value) => this.renderComment(value, true)).join('')
                + '</tbody></table>';
        }
        return result;
    }

    private renderDetailUserComment(detail: CosmoDbDetailData): string {
        const topics = detail.comment_topics.filter((value) => { return value.type === 'user'; });
        let result = '';
        if (topics.length > 0) {
            result = '<table class="list_table"><tbody>'
                + '<tr><th colspan="2">[en]User Comment[/en][ja]ユーザーコメント[/ja]</th></tr>'
                + topics.map((topic) => {
                    return this.renderComment(topic.comment, true)
                        + topic.replies.map((comment) => this.renderComment(comment, true)).join('');
                }).join('')
                + '</tbody></table>';
        }
        return result;
    }

    private renderDetailKeywords(detail: CosmoDbDetailData): string {
        const allKwIds: number[] = [];
        const getPid = (id: number): number => {
            const keyword = this.keyword.findOne({ kw_id: id });
            return keyword !== null ? keyword.pid : 0;
        };
        detail.keywords.forEach((id) => {
            if (!allKwIds.includes(id)) {
                allKwIds.push(id);
            }
            for (let pid = getPid(id); pid !== 0; pid = getPid(pid)) {
                if (!allKwIds.includes(pid)) {
                    allKwIds.push(pid);
                }
            }
        });
        const renderChildKeywords = (id: number): string => {
            const keywords = this.keyword.chain().find({ pid: id, kw_id: { '$in': allKwIds } }).simplesort('sort').data();
            if (keywords.length === 0) {
                return '';
            }
            const rows: string[] = [];
            rows.push('<ul>');
            keywords.forEach((keyword) => {
                rows.push(`<li>${keyword.keyword}${renderChildKeywords(keyword.kw_id)}</li>`);
            })
            rows.push('</ul>');
            return rows.join('');
        }
        return `<div class="keywords">${renderChildKeywords(0)}</div>`;
    }

    private renderDetailNews(basic: CosmoDbBasicData): string {
        let result = '';
        basic.items.forEach((item) => {
            if (item.type === 'file') {
                const date = Functions.formatDate(item.reg_date, 'YYYY-MM-DD');
                const fname = Functions.htmlspecialchars(item.path === '' ? item.name : `${item.path}/${item.name}`);
                const uname = Functions.htmlspecialchars(`${item.reg_user}`);
                result += `<div class="news"><span class="date">${date}</span><span class="filename">${fname}</span><span class="user">${uname}</span></div>`;
            }
        });
        return result;
    }

    private renderDetailLinks(basic: CosmoDbBasicData, detail: CosmoDbDetailData): string {
        const linkIn: string[] = [];
        const linkOut: string[] = [];
        detail.links.forEach((link) => {
            const note = Functions.htmlspecialchars(this.renderTextArea(link.note));
            const user = Functions.htmlspecialchars(link.user);
            if (link.type === 1) {
                const name = Functions.htmlspecialchars(basic.label);
                const href = this.getDetailUrl(parseInt(link.name));
                linkIn.push(`<div class="link"><span class="name"><a href="${href}">${name}</a></span><span class="note">${note}</span><span class="user">${user}</span></div>`);
            } else {
                const name = Functions.htmlspecialchars(link.name);
                const href = Functions.htmlspecialchars(link.href);
                linkOut.push(`<div class="link"><span class="name"><a href="${href}">${name}</a></span><span class="note">${note}</span><span class="user">${user}</span></div>`);
            }
        });
        let result: string = '';
        if (linkIn.length !== 0) {
            result += `<div class="links"><div class="title">[en]Relative data[/en][ja]関連データ[/ja]</div>${linkIn.join('')}</div>`
        }
        if (linkOut.length !== 0) {
            result += `<div class="links"><div class="title">[en]Relative URL[/en][ja]関連URL[/ja]</div>${linkOut.join('')}</div>`
        }
        return result;
    }

    private renderDetailImage(basic: CosmoDbBasicData, arg1: string, arg2: string): string {
        const dirs = arg1.split('|');
        const options = arg2.split('|');
        const images = basic.thumbnails.filter((thumbnail) => XRegExp(`^${dirs.join('|')}/`).test(thumbnail.url))
            .sort((a, b) => a.url === b.url ? 0 : (a.url > b.url ? 1 : -1));
        if (options.length !== 3 || images.length === 0) {
            return '';
        }
        const imgStyles: string[] = [];
        if (options[0] !== '') {
            const value = /^d+/.test(options[0]) ? `${options[0]}px` : options[0];
            imgStyles.push(`width:${value}`);
        }
        if (options[1] !== '') {
            const value = /^d+/.test(options[1]) ? `${options[1]}px` : options[1];
            imgStyles.push(`height:${value}`);
        }
        const imgStyle = imgStyles.length !== 0 ? 'style="' + imgStyles.join(';') + '" ' : '';
        const maxCol = Math.min(parseInt(options[2]), images.length);
        const rows: string[] = [];
        let cols: string[] = [];
        images.forEach((image) => {
            const url = Functions.htmlspecialchars(this.getThumbnailUrl(basic.id, image.url));
            const caption = Functions.htmlspecialchars(image.caption);
            cols.push(`<td><figure><a href="${url}"><img src="${url}" alt="${url}" ${imgStyle}/></a><figcaption>${caption}</figcaption></figure></td>`);
            if (cols.length === maxCol) {
                rows.push(`<tr>${cols.join('')}</tr>`);
                cols = [];
            }
        });
        while (cols.length !== 0) {
            cols.push(`<td>&nbsp;</td>`);
            if (cols.length === maxCol) {
                rows.push(`<tr>${cols.join('')}</tr>`);
                cols = [];
            }
        }
        let result = `<table class="images"><tbody>${rows.join('')}</tbody></table>`;
        return result;
    }

    private renderDetailDataTree(basic: CosmoDbBasicData): string {
        const renderChildTree = (path: string): string => {
            return '<ul>'
                + basic.items.filter((item) => item.path === path).sort((a, b) => {
                    if (a.type === 'dir' && b.type === 'file') {
                        return -1;
                    } else if (a.type === 'file' && b.type === 'dir') {
                        return 1;
                    }
                    return a.name === b.name ? 0 : (a.name > b.name ? 1 : -1)
                }).map((item) => {
                    const name = Functions.htmlspecialchars(item.name);
                    const path = item.path === '' ? item.name : `${item.path}/${item.name}`;
                    const url = Functions.htmlspecialchars(this.getDataUrl(basic.id, `${path}`));
                    const node = item.type === 'dir' ? `<div>${name}</div>${renderChildTree(`${path}`)}` : `<div><a href="${url}" download="${name}">${name}</a></div>`;
                    return `<li class="${item.type}">${node}</li>`;
                }).join('')
                + '</ul>';
        }
        return `<div class="datatree"><ul><li class="root"><div>ROOT</div>${renderChildTree('')}</li></ul></div>`;
    }

    private renderDetailHrefTab(basic: CosmoDbBasicData, no: string, label: string): string {
        const tab = parseInt(no);
        const href = this.getDetailUrl(basic.id, tab);
        return `<a href="${Functions.htmlspecialchars(href)}">${Functions.htmlspecialchars(label)}</a>`;
    }

    private renderDetailTab(tab: number, no: string, data: string): string {
        return tab === parseInt(no) ? data : '';
    }
}

export default CosmoDbContext;
