import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import PubmedPdfUtils, { PubmedPdfBreadcrumbsData } from './PubmedPdfUtils';

interface Props {
    lang: MultiLang;
    breadcrumbs: PubmedPdfBreadcrumbsData[];
}

const PubmedPdfBreadcrumbs: React.FC<Props> = (props: Props) => {
    const { lang, breadcrumbs } = props;
    const items: PubmedPdfBreadcrumbsData[] = [
        { id: 'h', name: '[en]Home[/en][ja]ホーム[/ja]', link: '/' },
        { id: 'm', name: PubmedPdfUtils.getName(), link: PubmedPdfUtils.getIndexUrl() }
    ];
    return <div className="breadcrumbs">
        {items.map((item, index) => {
            const name = Functions.mlang(item.name, lang);
            return <span key={item.id}>
                {index !== 0 && <>&nbsp;&raquo;&nbsp;</>}
                <Link to={item.link}>{name}</Link>
            </span>;
        })}
        {breadcrumbs.map((item, index) => {
            const name = Functions.mlang(item.name, lang);
            return <span key={item.id}>
                &nbsp;&raquo;&nbsp;
                {index === (breadcrumbs.length - 1) ? name : <Link to={item.link}>{name}</Link>}
            </span>;
        })}
    </div>;
}

export default PubmedPdfBreadcrumbs;
