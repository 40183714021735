import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/pubmedpdf.css';
import PubmedPdfBreadcrumbs from './lib/PubmedPdfBreadcrumbs';
import PubmedPdfUtils, { PubmedPdfBreadcrumbsData } from './lib/PubmedPdfUtils';


interface Props {
    lang: MultiLang;
    initial: string;
}

interface State {
    loading: boolean;
    name: string;
}

class PubmedPdfAuthorsByInitial extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, initial } = this.props;
        if (prevProps.lang !== lang || prevProps.initial !== initial) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await PubmedPdfUtils.initialize();
        if (this.isActive) {
            const name = PubmedPdfUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, initial } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const authors = PubmedPdfUtils.getAuthors(initial);
        const breadcrumbs: PubmedPdfBreadcrumbsData[] = [
            { id: `a-${initial}`, name: initial, link: PubmedPdfUtils.getAuthorInitialUrl(initial) }
        ];
        return (
            <div className="pubmedpdf" >
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <PubmedPdfBreadcrumbs lang={lang} breadcrumbs={breadcrumbs} />
                <hr />
                <div className="authors">
                    <div className="author-group">
                        {authors.map((author) => {
                            return <span className="author" key={author.id}><Link to={PubmedPdfUtils.getAuthorUrl(author.id, 0)}>{Functions.mlang(author.name, lang)}</Link> ({author.count})</span>;
                        })}
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default PubmedPdfAuthorsByInitial;
