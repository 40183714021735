import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props {
    lang: MultiLang;
}

const XwordsCategoryFieldset: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const category = XwordsUtils.getCategories();
    return <fieldset className="item category">
        <legend className="title" > {Functions.mlang('[en]Categories[/en][ja]用途別分類（カテゴリー）別[/ja]', lang)}</legend>
        <div className="body">
            {category.map((category) => {
                const url = XwordsUtils.getCategoryUrl(category.id);
                const title = '[' + category.total + ']';
                const name = Functions.mlang(category.name, lang);
                return <span key={category.id} ><Link to={url} title={title}>{name}</Link></span>
            })}
            <span><Link to={XwordsUtils.getCategoryUrl(0)}>{Functions.mlang('[en]List of Categories[/en][ja]分類説明一覧[/ja]', lang)}</Link></span>
        </div>
    </fieldset>;
}

export default XwordsCategoryFieldset;
