import CosmoDbContext from './CosmoDbContext';

class CosmoDbUtils {

    private static contexts: Map<string, CosmoDbContext> = new Map<string, CosmoDbContext>();

    private constructor() {
        // blcok to access from outside of this class
    }

    public static getContext(modulepath: string): CosmoDbContext {
        let context = this.contexts.get(modulepath);
        if (typeof context === 'undefined') {
            context = new CosmoDbContext(modulepath);
            this.contexts.set(modulepath, context);
        }
        return context;
    }

    public static getThumbnailSize(paramSize: string | null, thumbSize: string) {
        const options = thumbSize.split(';').map((value) => { return value.split(','); });
        if (options.length === 0 || options.filter((value) => { return value.length !== 4; }).length !== 0) {
            return null;
        }
        const size = paramSize === null ? options[0][0] : paramSize;
        const option = options.find((value) => { return value[0] === size; });
        if (typeof option === 'undefined') {
            return null;
        }
        const imgWidth = /^\d+$/.test(option[1]) ? parseInt(option[1]) : 0;
        const imgHeight = /^\d+$/.test(option[2]) ? parseInt(option[2]) : 0;
        const maxCol = /^\d+$/.test(option[3]) ? parseInt(option[3]) : 0;
        if (imgHeight < 0 || imgWidth < 0 || maxCol <= 0) {
            return null;
        }
        return {
            name: option[0],
            height: imgHeight,
            width: imgWidth,
            maxCol: maxCol,
        }
    }
}

export default CosmoDbUtils;
