import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props {
    lang: MultiLang;
    catId: number;
}

const XwordsRankingFieldset: React.FC<Props> = (props: Props) => {
    const { lang, catId } = props;
    const ranking = XwordsUtils.getRankingEntries(catId);
    const title = catId === 0 ? '[en]Ranking[/en][ja]ランキング[/ja]' : '[en]Ranking in this Category[/en][ja]この分野でのランキング[/ja]';
    return <fieldset className="item ranking">
        <legend className="title">{Functions.mlang(title, lang)}</legend>
        <div className="body">
            <ul>
                {ranking.map((entry, idx) => {
                    return <li key={entry.id}><span className="info">{(idx + 1) + Functions.ordinal(idx + 1)}</span> <Link to={XwordsUtils.getEntryUrl(entry.id)}>{Functions.mlang(entry.term, lang)}</Link></li>
                })}
            </ul>
        </div>
    </fieldset>;
}

export default XwordsRankingFieldset;
