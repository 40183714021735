import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/xwords.css';
import XwordsBreadcrumbs from './lib/XwordsBreadcrumbs';
import XwordsEntries from './lib/XwordsEntries';
import XwordsSearchFieldset from './lib/XwordsSearchFieldset';
import XwordsUtils, { XwordsSearchResult } from './lib/XwordsUtils';

interface Props {
    lang: MultiLang;
    type: number;
    catId: number;
    andor: string;
    term: string;
    start: number;
}

interface State {
    loading: boolean;
    name: string | null;
    result: XwordsSearchResult;
}

class XwordsSearch extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: null,
            result: { total: 0, entries: [] },
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, type, catId, andor, term, start } = this.props;
        if (prevProps.lang !== lang || prevProps.type !== type || prevProps.catId !== catId || prevProps.andor !== andor || prevProps.term !== term || prevProps.start !== start) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await XwordsUtils.initialize();
        if (this.isActive) {
            const { type, catId, andor, term, start } = this.props;
            const name = XwordsUtils.getName();
            const result = XwordsUtils.search(type, catId, andor, term, start);
            this.setState({ loading: false, name, result });
        }
    }

    render() {
        const { lang, type, catId, andor, term, start } = this.props;
        const { loading, name, result } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (name === null) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const title = Functions.mlang('[en]Search[/en][ja]検索[/ja]', lang);
        const baseUrl = XwordsUtils.getSearchUrl(type, catId, andor, term);
        const etitle = result.total === 0 ?
            Functions.mlang('[en]No entries found.[/en][ja]該当なし。再度お試しください。[/ja]', lang) :
            Functions.mlang('[en]' + result.total + ' entries found.[/en][ja]上記の条件で ' + result.total + ' 件見つかりました。[/ja]', lang);
        return (
            <div className="xwords">
                <Helmet>
                    <title>{title} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{title}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XwordsBreadcrumbs lang={lang} isSearch={true} />
                <div className="row">
                    <XwordsSearchFieldset lang={lang} type={type} catId={catId} andor={andor} term={term} />
                </div>
                <h4 className="title">{etitle}</h4>
                {result.total > 0 && <XwordsEntries lang={lang} baseUrl={baseUrl} result={result} start={start} />}
            </div>
        );
    }
}
export default XwordsSearch;
