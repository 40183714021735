import React, { ChangeEvent, Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    type: number;
    catId: number;
    andor: string;
    term: string;
}

interface State {
    type: number;
    catId: number;
    andor: string;
    term: string;
}

class XwordsSearchFieldset extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const { type, catId, andor, term } = props;
        this.state = { type, catId, andor, term };
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeCatId = this.handleChangeCatId.bind(this);
        this.handleChangeAndOr = this.handleChangeAndOr.bind(this);
        this.handleChangeTerm = this.handleChangeTerm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeType(event: ChangeEvent<HTMLSelectElement>) {
        const type = parseInt(event.target.value.trim());
        this.setState({ type });
    }

    handleChangeCatId(event: ChangeEvent<HTMLSelectElement>) {
        const catId = parseInt(event.target.value.trim());
        this.setState({ catId });
    }

    handleChangeAndOr(event: ChangeEvent<HTMLSelectElement>) {
        const andor = event.target.value.trim();
        this.setState({ andor });
    }

    handleChangeTerm(event: ChangeEvent<HTMLInputElement>) {
        const term = event.target.value;
        this.setState({ term });
    }

    handleSubmit(event: FormEvent<HTMLFormElement>) {
        const { type, catId, andor, term } = this.state;
        const term_ = term.trim();
        event.preventDefault();
        if (term_.length > 0) {
            const url = XwordsUtils.getSearchUrl(type, catId, andor, term_);
            this.props.history.push(url);
        }
    }

    render() {
        const { lang } = this.props;
        const { type, catId, andor, term } = this.state;
        const categories = XwordsUtils.getCategories();
        return <fieldset className="item search">
            <legend className="title">{Functions.mlang('[en]Search[/en][ja]検索[/ja]', lang)}</legend>
            <div className="body">
                <form onSubmit={this.handleSubmit}>
                    <dl>
                        <dt>{Functions.mlang('[en]Target[/en][ja]検索対象[/ja]', lang)}</dt>
                        <dd>
                            <select onChange={this.handleChangeType} value={type}>
                                <option value="1">{Functions.mlang('[en]Term[/en][ja]見出し語[/ja]', lang)}</option>
                                <option value="2">{Functions.mlang('[en]Yomi[/en][ja]読み方[/ja]', lang)}</option>
                                <option value="3">{Functions.mlang('[en]Definition[/en][ja]説明文[/ja]', lang)}</option>
                                <option value="4">{Functions.mlang('[en]Term+Yomi+Definition[/en][ja]見出し語+読み方+説明文[/ja]', lang)}</option>
                            </select>
                        </dd>
                        <dt>{Functions.mlang('[en]Category[/en][ja]用途別分類[/ja]', lang)}</dt>
                        <dd>
                            <select onChange={this.handleChangeCatId} value={catId}>
                                <option value="0">{Functions.mlang('[en]ANY[/en][ja]全分類（カテゴリー）[/ja]', lang)}</option>
                                {categories.map((value) => <option key={value.id} value={value.id}>{Functions.mlang(value.name, lang)}</option>)}
                            </select>
                        </dd>
                        <dt>{Functions.mlang('[en]Condition[/en][ja]検索の種類[/ja]', lang)}</dt>
                        <dd>
                            <select onChange={this.handleChangeAndOr} value={andor}>
                                <option value="AND">{Functions.mlang('[en]AND[/en][ja]すべての語に一致するもの[/ja]', lang)}</option>
                                <option value="OR">{Functions.mlang('[en]OR[/en][ja]いずれかの語に一致するもの[/ja]', lang)}</option>
                                <option value="EXACT">{Functions.mlang('[en]EXACT[/en][ja]スペースも含め１語として[/ja]', lang)}</option>
                            </select>
                        </dd>
                        <dt>{Functions.mlang('[en]Word[/en][ja]検索語[/ja]', lang)}</dt>
                        <dd>
                            <input type="text" name="term" onChange={this.handleChangeTerm} value={term} />
                            <input className="formButton" type="submit" value={Functions.mlang('[en]Search![/en][ja]検索！[/ja]', lang)} />
                        </dd>
                    </dl>
                </form>
            </div>
        </fieldset>;
    }
}

export default withRouter(XwordsSearchFieldset);
