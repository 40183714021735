import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/xwords.css';
import XwordsBreadcrumbs from './lib/XwordsBreadcrumbs';
import XwordsCategoryFieldset from './lib/XwordsCategoryFieldset';
import XwordsEntries from './lib/XwordsEntries';
import XwordsInitialFieldset from './lib/XwordsInitialFieldset';
import XwordsUtils from './lib/XwordsUtils';

interface Props {
    lang: MultiLang;
    catId: number;
    initial: string;
    start: number;
}

interface State {
    loading: boolean;
    name: string | null;
}

class XwordsLetter extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: null,
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, catId, initial } = this.props;
        if (prevProps.lang !== lang || prevProps.catId !== catId || prevProps.initial !== initial) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await XwordsUtils.initialize();
        if (this.isActive) {
            const name = XwordsUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, catId, initial, start } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (null === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const ctitle = Functions.mlang(XwordsUtils.getCategoryName(catId), lang);
        const ititle = Functions.mlang(XwordsUtils.getInitialName(initial), lang);
        if (ctitle === '' || ititle === '') {
            return <PageNotFound lang={lang} />;
        }
        const title = Functions.mlang('[en]Initial[/en][ja]頭文字[/ja] : ', lang) + ititle;
        const baseUrl = XwordsUtils.getLetterUrl(initial, catId);
        const result = {
            total: XwordsUtils.getCountEntries(catId, initial),
            entries: XwordsUtils.getEntries(catId, initial, start)
        };
        return (
            <div className="xwords">
                <Helmet>
                    <title>{ititle} - {ctitle} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{title}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XwordsBreadcrumbs lang={lang} catId={catId} initial={initial} />
                <div className="row">
                    <XwordsCategoryFieldset lang={lang} />
                </div>
                <div className="row">
                    <XwordsInitialFieldset lang={lang} catId={catId} />
                </div>
                <h4 className="title">{Functions.mlang(XwordsUtils.getInitialName(initial), lang)}</h4>
                <XwordsEntries lang={lang} baseUrl={baseUrl} result={result} start={start} />
            </div>
        );
    }
}
export default XwordsLetter;
