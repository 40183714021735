import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props {
    lang: MultiLang;
    catId?: number;
    initial?: string;
    entryId?: number;
    isSearch?: boolean;
}

const XwordsBreadcrumbs: React.FC<Props> = (props: Props) => {
    const { lang, catId, initial, entryId, isSearch } = props;
    const items: { id: string, name: string, link: string }[] = [];
    items.push({ id: 'h', name: '[en]Home[/en][ja]ホーム[/ja]', link: '/' });
    items.push({ id: 'm', name: XwordsUtils.getName(), link: XwordsUtils.getIndexUrl() });
    if (typeof isSearch !== undefined && isSearch) {
        items.push({ id: 's', name: '[en]Search[/en][ja]検索[/ja]', link: '/' });
    } else if (typeof catId !== 'undefined') {
        const cname = catId === 0 && typeof initial === 'undefined' ? '[en]List of Categories[/en][ja]分類説明一覧[/ja]' : XwordsUtils.getCategoryName(catId);
        items.push({ id: 'c' + catId, name: cname, link: XwordsUtils.getCategoryUrl(catId) });
        if (typeof initial !== 'undefined') {
            items.push({ id: 'i' + initial, name: XwordsUtils.getInitialName(initial), link: XwordsUtils.getLetterUrl(initial, catId) });
            if (typeof entryId !== 'undefined') {
                items.push({ id: 'e' + entryId, name: XwordsUtils.getEntryName(entryId), link: XwordsUtils.getEntryUrl(entryId) })
            }
        }
    }

    return <div className="breadcrumbs">
        {items.map((item, index) => {
            const name = Functions.mlang(item.name, lang);
            return <span key={item.id}>
                {index !== 0 && <>&nbsp;&raquo;&nbsp;</>}
                {index === (items.length - 1) ? name : <Link to={item.link}>{name}</Link>}
            </span>;
        })}
    </div>;
}

export default XwordsBreadcrumbs;
