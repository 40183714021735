import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props {
    lang: MultiLang;
}

const XwordsRandomFieldset: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const categories = XwordsUtils.getCategories();
    const random = XwordsUtils.getRandomEntry();
    if (random === null) {
        return null;
    }
    const randomCategory = categories.find((category) => { return category.id === random.cat_id; });
    if (typeof randomCategory === 'undefined') {
        return null;
    }
    const randomDefinition = Functions.mlang(random.definition, lang);

    return <fieldset className="item random">
        <legend className="title">{Functions.mlang('[en]Random[/en][ja]ランダム[/ja]', lang)}</legend>
        <div className="body">
            <div className="term">
                <Link to={XwordsUtils.getEntryUrl(random.id)}>{Functions.mlang(random.term, lang)}</Link> <span className="info">[{Functions.mlang('[en]Category[/en][ja]用途[/ja]', lang)}: <Link to={XwordsUtils.getCategoryUrl(randomCategory.id)}>{Functions.mlang(randomCategory.name, lang)}</Link>]</span>
            </div>
            <div className="definition">
                <span className="info">{Functions.mlang('[en]Definition: [/en][ja]解説: [/ja]', lang)}</span>{randomDefinition.substr(0, 30)}{randomDefinition.length > 30 && <>...</>}
            </div>
        </div>
    </fieldset>;
}

export default XwordsRandomFieldset;
