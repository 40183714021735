import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/pubmedpdf.css';
import PubmedPdfBreadcrumbs from './lib/PubmedPdfBreadcrumbs';
import PubmedPdfListItemPaper from './lib/PubmedPdfPaper';
import PubmedPdfUtils, { PubmedPdfBreadcrumbsData } from './lib/PubmedPdfUtils';

interface Props {
    lang: MultiLang;
    journalId: number;
    year: number;
}

interface State {
    loading: boolean;
    name: string;
}

class PubmedPdfPapersByJournal extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, journalId, year } = this.props;
        if (prevProps.lang !== lang || prevProps.journalId !== journalId || prevProps.year !== year) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await PubmedPdfUtils.initialize();
        if (this.isActive) {
            const name = PubmedPdfUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, journalId, year } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const journal = PubmedPdfUtils.getJournal(journalId);
        if (journal === null) {
            return <PageNotFound lang={lang} />;
        }
        const breadcrumbs: PubmedPdfBreadcrumbsData[] = [
            { id: `j.${journal.id}`, name: Functions.mlang(journal.name, lang), link: PubmedPdfUtils.getJournalUrl(journalId, 0) }
        ];
        if (year !== 0) {
            breadcrumbs.push({ id: `y.${year}`, name: `${year}`, link: PubmedPdfUtils.getJournalUrl(journalId, year) });
        }
        const papers = PubmedPdfUtils.getPapersByJournalId(journalId, year);
        const years = PubmedPdfUtils.getYearsByPapers(papers);
        return (
            <div className="pubmedpdf">
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <PubmedPdfBreadcrumbs lang={lang} breadcrumbs={breadcrumbs} />
                <hr />
                {year === 0 && <>
                    <div className="years">
                        {years.map((year) => {
                            return <div className="year" key={year.year}><Link to={PubmedPdfUtils.getJournalUrl(journalId, year.year)}>{year.year}</Link> ({year.count})</div>;
                        })}
                    </div>
                    <hr />
                </>}
                <div className="papers">
                    {papers.map((paper) => {
                        return <PubmedPdfListItemPaper key={paper.id} lang={lang} paper={paper} />;
                    })}
                </div>
            </div>
        );
    }
}

export default PubmedPdfPapersByJournal;
