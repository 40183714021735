import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsEntriesPageNavi from './XwordsEntriesPageNavi';
import XwordsUtils, { XwordsEntryData, XwordsSearchResult } from './XwordsUtils';

interface Props {
    lang: MultiLang;
    baseUrl: string;
    result: XwordsSearchResult;
    start: number;
}

const XwordsEntries: React.FC<Props> = (props: Props) => {
    const { lang, baseUrl, result, start } = props;
    const definition = (entry: XwordsEntryData): string => {
        const text = Functions.mlang(entry.definition, lang);
        return text.substr(0, 50) + (text.length > 50 ? '...' : '');
    };
    return <div className="entries">
        {result.entries.length === 0 && <>{Functions.mlang('[en]No entries found[/en][ja]エントリが見つかりませんでした[/ja]', lang)}</>}
        <XwordsEntriesPageNavi lang={lang} baseUrl={baseUrl} total={result.total} start={start} />
        {result.entries.map((entry) => {
            return <div key={entry.id} className="entry">
                <div className="title"><Link className="link" to={XwordsUtils.getEntryUrl(entry.id)}>{Functions.mlang(entry.term, lang)}</Link></div>
                <div className="description">
                    <span className="yomi">{Functions.mlang('[en]Yomi[/en][ja]読み[/ja] : ' + entry.yomi, lang)}</span>
                    <span className="definition">{Functions.mlang('[en]Definition[/en][ja]解説[/ja] : ', lang) + definition(entry)}</span>
                </div>
            </div>;
        })}
        <XwordsEntriesPageNavi lang={lang} baseUrl={baseUrl} total={result.total} start={start} />
    </div>
}

export default XwordsEntries;
