import React from 'react';
import XoopsCode from '../../common/lib/XoopsCode';
import { MultiLang } from '../../config';
import CosmoDbContext, { CosmoDbBasicData, CosmoDbListData } from './CosmoDbContext';
import CosmoDbListSortBar from './CosmoDbListSortBar';

interface Props {
    lang: MultiLang;
    context: CosmoDbContext;
    list: CosmoDbListData;
    params: URLSearchParams;
    data: CosmoDbBasicData[];
}

const CosmoDbListListView: React.FC<Props> = (props: Props) => {
    const { lang, context, list, params, data } = props;
    const components = context.getComponents();
    let rowHeader = list.list_th;
    components.forEach((value) => {
        rowHeader = rowHeader.replace(`{${value.name}}`, value.tag);
    })
    return <div className="list-view">
        <CosmoDbListSortBar lang={lang} context={context} list={list} params={params} />
        <table className="list-table">
            <tbody>
                <tr>
                    <XoopsCode lang={lang} text={rowHeader} dohtml={true} />
                </tr>
                {data.map((value) => {
                    let row = context.renderBasic(value, list.template, true);
                    return <tr key={value.id}>
                        <XoopsCode lang={lang} text={row} dohtml={true} />
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
};

export default CosmoDbListListView;
