import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils, { XWORDS_INITIAL_KEYS } from './XwordsUtils';

interface Props {
    lang: MultiLang;
    catId: number;
}

const XwordsInitialFieldset: React.FC<Props> = (props: Props) => {
    const { lang, catId } = props;
    const initial = XwordsUtils.getCountInitials(catId);
    const initialsAlpha: JSX.Element[] = [];
    const initialsKana: JSX.Element[] = [];
    XWORDS_INITIAL_KEYS.forEach((value, idx) => {
        const num = initial[value.key];
        const url = XwordsUtils.getLetterUrl(value.key, catId);
        const label = Functions.mlang(value.label, lang);
        const name = num === 0 ? label : <Link to={url} title={`[ ${num} ]`}>{label}</Link>;
        const el = <span key={value.key}>{name}</span>;
        if (idx < 26) {
            initialsAlpha.push(el);
        } else {
            initialsKana.push(el);
        }
    });
    return <fieldset className="item initial">
        <legend className="title">{Functions.mlang('[en]Initials[/en][ja]頭文字（イニシャル）別[/ja]', lang)}</legend>
        <div className="body">
            <div>{initialsAlpha}</div>
            <div>{initialsKana}</div>
        </div>
    </fieldset>;
}

export default XwordsInitialFieldset;
