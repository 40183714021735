import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/pubmedpdf.css';
import PubmedPdfUtils from './lib/PubmedPdfUtils';


interface Props {
    lang: MultiLang;
    id: number;
}

interface State {
    loading: boolean;
    name: string;
}

class PubmedPdfPaperDesc extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang } = this.props;
        if (prevProps.lang !== lang) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await PubmedPdfUtils.initialize();
        if (this.isActive) {
            const name = PubmedPdfUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, id } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const paper = PubmedPdfUtils.getPaper(id);
        if (paper === null) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const journal = PubmedPdfUtils.getJournal(paper.journal_id);
        const title = Functions.mlang(paper.title, lang);
        const journalName = journal !== null ? Functions.mlang(journal.name, lang) : '';
        const pubmedLink = paper.pmid > 0 ? <a href={`https://www.ncbi.nlm.nih.gov/pubmed/${paper.pmid}`} target="_blank" rel="noopener noreferrer">{paper.pmid}</a> : '';
        return (
            <div className="pubmedpdf" >
                <Helmet>
                    <title>{title} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <NoticeSiteHasBeenArchived lang={lang} />
                <div className="paper_desc">
                    <table className="outer">
                        <tbody>
                            <tr>
                                <td className="head">PMID</td>
                                <td className="odd">{pubmedLink}</td>
                            </tr>
                            <tr>
                                <td className="head">Title</td>
                                <td className="even">{title}</td>
                            </tr>
                            <tr>
                                <td className="head">Author</td>
                                <td className="odd">{paper.author_ids.map((authorId, index) => {
                                    const author = PubmedPdfUtils.getAuthor(authorId);
                                    return <Fragment key={authorId}>
                                        {index !== 0 && (paper.author_ids.length - 1 !== index ? <>, </> : <> and </>)}
                                        {author !== null && author.name}
                                    </Fragment>;
                                })}</td>
                            </tr>
                            <tr>
                                <td className="head">Journal/Book</td>
                                <td className="even">{journalName}</td>
                            </tr>
                            <tr>
                                <td className="head">Year</td>
                                <td className="odd">{paper.year}</td>
                            </tr>
                            <tr>
                                <td className="head">vol/pp</td>
                                <td className="even">{paper.volume && `${paper.volume} / `}{paper.page}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default PubmedPdfPaperDesc;
