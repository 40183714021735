import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import CosmoDbContext from './CosmoDbContext';

interface Props {
    lang: MultiLang;
    context: CosmoDbContext;
    params: URLSearchParams;
    total: number;
}

const CosmoDbListPageNavi: React.FC<Props> = (props: Props) => {
    const { lang, context, params, total } = props;
    const paramItem = params.get('item');
    const paramN = params.get('n');
    const offset = (paramItem !== null && /^\d+$/.test(paramItem)) ? parseInt(paramItem) : 0;
    let limit = (paramN !== null && /^\d+$/.test(paramN)) ? parseInt(paramN) : 20;
    if (offset > total) {
        return null;
    }
    const now = Math.ceil((offset + 1) / limit);
    const min = 1;
    const max = Math.ceil(total / limit);
    const prev = now === 1 ? 0 : now - 1;
    const next = now === max ? 0 : now + 1;
    let rangeMin = now - 3;
    if (rangeMin < 2) {
        rangeMin = 2;
    }
    let rangeMax = now + 3;
    if (rangeMax > max - 1) {
        rangeMax = max - 1;
    }
    const link = (num: number, el?: JSX.Element) => {
        const label = el || num;
        const nParams = new URLSearchParams(params);
        if (num - 1 > 0) {
            nParams.set('item', String((num - 1) * limit));
        } else {
            nParams.set('item', '0');
        }
        const url = context.getListUrl(nParams);
        return num === now ? <>({label})</> : <Link to={url}>{label}</Link>
    }
    const items: JSX.Element[] = [];
    if (prev !== 0) {
        items.push(link(prev, <>&laquo;</>));
    }
    items.push(link(min));
    if (now - min > 4) {
        items.push(<>...</>);
    }
    for (let range = rangeMin; range <= rangeMax; range++) {
        items.push(link(range));
    }
    if (max - now > 4) {
        items.push(<>...</>);
    }
    items.push(link(max));
    if (next !== 0) {
        items.push(link(next, <>&raquo;</>));
    }
    const rangeS = offset + 1;
    const rangeE = (offset + limit) < total ? offset + limit : total;

    return <nav className="pagenavi">
        <span className="label">{Functions.mlang(`[en]${total} Hits (${rangeS}-${rangeE})[/en][ja]全${total}件 (${rangeS}-${rangeE}件目)[/ja]`, lang)}</span>
        {total > limit && <span className="pages">{items.map((el, index) => <Fragment key={index}>{index !== 0 && <>&nbsp;</>}{el}</Fragment>)}</span>}
    </nav>;
}

export default CosmoDbListPageNavi;
