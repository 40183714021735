import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import PubmedPdfUtils, { PubmedPdfPaperData } from './PubmedPdfUtils';

interface Props {
    lang: MultiLang;
    paper: PubmedPdfPaperData;
}

const PubmedPdfListItemPaper: React.FC<Props> = (props: Props) => {
    const { lang, paper } = props;
    const url = PubmedPdfUtils.getPaperDescUrl(paper.id);
    const journal = PubmedPdfUtils.getJournal(paper.journal_id);
    const title = Functions.mlang(paper.title, lang);
    const journalName = journal !== null ? Functions.mlang(journal.name, lang) : '';
    const pubmedLink = paper.pmid > 0 ? <> [<a href={`https://www.ncbi.nlm.nih.gov/pubmed/${paper.pmid}`} target="_blank" rel="noopener noreferrer">PubMed: {paper.pmid}</a>]</> : '';
    return <div className="paper">
        <strong>
            {paper.author_ids.map((authorId, index) => {
                const author = PubmedPdfUtils.getAuthor(authorId);
                return <Fragment key={authorId}>
                    {index !== 0 && (paper.author_ids.length - 1 !== index ? <>, </> : <> and </>)}
                    {author !== null && author.name}
                </Fragment>;
            })}
            &nbsp;({paper.year}).
        </strong><br />
        <Link to={url}>{title}</Link><br />
        <em>{journalName}</em>
        {paper.volume !== '' && `, ${paper.volume}`}
        {paper.page !== '' && `, ${paper.page}`}
        .{pubmedLink}
    </div>;
}

export default PubmedPdfListItemPaper;
