import React from 'react';
import XoopsCode from '../../common/lib/XoopsCode';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import CosmoDbContext, { CosmoDbBasicData, CosmoDbListData } from './CosmoDbContext';
import CosmoDbListSortBar from './CosmoDbListSortBar';
import CosmoDbUtils from './CosmoDbUtils';

interface Props {
    lang: MultiLang;
    context: CosmoDbContext;
    list: CosmoDbListData;
    params: URLSearchParams;
    data: CosmoDbBasicData[];
}

const CosmoDbListThumbnailView: React.FC<Props> = (props: Props) => {
    const { lang, context, list, params, data } = props;
    const size = CosmoDbUtils.getThumbnailSize(params.get('size'), list.thumb_size);
    if (size === null) {
        return null;
    }
    const rows: JSX.Element[] = [];
    let cols: JSX.Element[] = [];
    let col = 1;
    const thumbRegex = new RegExp(`^${list.thumb_dir}/`);
    const imgStyles: string[] = [];
    if (size.height > 0) {
        imgStyles.push(`height:${size.height}px`);
    }
    if (size.width > 0) {
        imgStyles.push(`width:${size.width}px`);
    }
    const imgStyle = imgStyles.length !== 0 ? 'style="' + imgStyles.join(';') + '" ' : '';
    data.forEach((value) => {
        const thumbs = value.thumbnails.filter((thumbnail) => {
            return thumbRegex.test(thumbnail.url);
        });
        const url = context.getDetailUrl(value.id);
        if (thumbs.length === 0) {
            thumbs.push({ url: '', caption: '' });
        }
        thumbs.forEach((thumb, tindex) => {
            const imgUrl = context.getThumbnailUrl(value.id, thumb.url);
            const style = thumb.url === '' ? '' : imgStyle;
            const image = `<img src="${Functions.htmlspecialchars(imgUrl)}" alt="${Functions.htmlspecialchars(thumb.url)}" ${style}/>`;
            const template = list.template.replace('{Image}', `<div ${imgStyle}><a href="${Functions.htmlspecialchars(url)}">${image}</a></div>`);
            cols.push(<td key={`${value.id}.${tindex}`}><XoopsCode lang={lang} text={context.renderBasic(value, template, true)} dohtml={true} /></td>);
            if (cols.length === size.maxCol) {
                rows.push(<tr key={col}>{cols}</tr>);
                cols = [];
            }
            col++;
        });
    });
    while (cols.length !== 0) {
        cols.push(<td key={`0.${col}`}></td>);
        if (cols.length === size.maxCol) {
            rows.push(<tr key={col}>{cols}</tr>);
            cols = [];
        }
        col++;
    }
    return <div className="thumbnail-view">
        <CosmoDbListSortBar lang={lang} context={context} list={list} params={params} />
        <table className="thumbnail-table">
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>;
};

export default CosmoDbListThumbnailView;
