import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import mlangEnglish from '../assets/images/mlang_english.gif';
import mlangJapanese from '../assets/images/mlang_japanese.gif';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    className?: string;
    image?: string;
}

const langResources = {
    en: { image: mlangEnglish, title: 'English' },
    ja: { image: mlangJapanese, title: 'Japanese' },
};

const LangFlag = (props: Props) => {
    const { lang, className, image } = props;
    const params = new URLSearchParams(props.location.search);
    const flagLang = lang === 'en' ? 'ja' : 'en';
    params.set('ml_lang', flagLang);
    const url = props.location.pathname + '?' + params.toString();
    const imageSrc = image || langResources[flagLang].image;
    return (
        <Link className={className} to={url}>
            <img src={imageSrc} alt={langResources[flagLang].title} title={langResources[flagLang].title} />
        </Link>
    );
}


export default withRouter(LangFlag);
