import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PageNotFound from '../../common/lib/PageNotFound';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import CosmoDbContext from './CosmoDbContext';

interface Props {
    lang: MultiLang;
    context: CosmoDbContext;
    params: URLSearchParams;
}

const CosmoDbListViewSwitcher: React.FC<Props> = (props: Props) => {
    const { lang, context, params } = props;
    let viewSwitcher: JSX.Element[] = [];
    const paramId = params.get('id');
    if (paramId === null || !/^\d+$/.test(paramId)) {
        return <PageNotFound lang={lang} />;
    }
    const id = parseInt(paramId);
    const lists = context.getLists();
    lists.forEach((value, index) => {
        const name = Functions.mlang(value.name, lang);
        if (index > 0) {
            viewSwitcher.push(<Fragment key={`${index}|`}> | </Fragment>);
        }
        if (value.list_id === id) {
            viewSwitcher.push(<span key={value.list_id}>{name}</span>);
        }
        else {
            const nParams = new URLSearchParams(params);
            nParams.set('id', value.list_id.toString());
            const url = context.getListUrl(nParams);
            viewSwitcher.push(<Link key={value.list_id} to={url}>{name}</Link>);
        }
    });
    return <div className="view-switcher">
        {viewSwitcher}
    </div>;
};

export default CosmoDbListViewSwitcher;
