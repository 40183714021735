const SITE_TITLE = 'Invertebrate Brain Platform';
const SITE_SLOGAN = '';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2780881-1';
const PICO_MODULES = ['documents', 'htmldocs'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    PICO_MODULES,
}

export default Config;