import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import CosmoDbContext, { CosmoDbBasicData, CosmoDbDetailData } from './lib/CosmoDbContext';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    context: CosmoDbContext;
    id: number;
    tab: number;
}

interface State {
    loading: boolean;
    basic: CosmoDbBasicData | null;
    detail: CosmoDbDetailData | null;
}

class CosmoDbDetail extends Component<Props, State> {

    private isActive: boolean;

    public constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            basic: null,
            detail: null,
        };
    }

    public componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, context, id, tab } = this.props;
        if (prevProps.lang !== lang || prevProps.context.getUrl('') !== context.getUrl('') || prevProps.id !== id || prevProps.tab !== tab) {
            this.updatePage();
        }
    }

    public componentWillUnmount() {
        this.isActive = false;
    }

    public async updatePage() {
        const { context, id } = this.props;
        const basic = context.getBasic(id);
        const detail = basic !== null ? await context.getDetail(id) : null;
        if (this.isActive) {
            this.setState({ loading: false, basic, detail });
        }
    }

    public render() {
        const { lang, context, tab } = this.props;
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.basic === null || this.state.detail === null) {
            return <PageNotFound lang={lang} />;
        }
        const html = context.renderDetail(this.state.basic, this.state.detail, tab);
        return <div className="detail">
            <Helmet>
                <title>{Functions.mlang(this.state.basic.label, lang)} - {Functions.mlang(context.getModuleName(), lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <XoopsCode lang={lang} text={html} dohtml={true} />
        </div>;
    }
}

export default withRouter(CosmoDbDetail);
