import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/xwords.css';
import XwordsBreadcrumbs from './lib/XwordsBreadcrumbs';
import XwordsCategoryFieldset from './lib/XwordsCategoryFieldset';
import XwordsEntries from './lib/XwordsEntries';
import XwordsInitialFieldset from './lib/XwordsInitialFieldset';
import XwordsRankingFieldset from './lib/XwordsRankingFieldset';
import XwordsRecentlyFieldset from './lib/XwordsRecentlyFieldset';
import XwordsUtils from './lib/XwordsUtils';

interface CategoryListProps {
    lang: MultiLang;
}

const CategoryList: React.FC<CategoryListProps> = (props: CategoryListProps) => {
    const { lang } = props;
    const categories = XwordsUtils.getCategories();
    const title = Functions.mlang('[en]List of Categories[/en][ja]分類説明一覧[/ja]', lang);
    return <div className="categories">
        <h4 className="title">{title}</h4>
        {categories.map((category) => {
            return <div key={category.id} className="category">
                <Link className="link" to={XwordsUtils.getCategoryUrl(category.id)}>{Functions.mlang(XwordsUtils.getCategoryName(category.id), lang)}</Link>
                <span className="num">[{Functions.mlang('[en]Number of entries:[/en][ja]登録数[/ja]' + category.total + '[en]entries[/en][ja]語[/ja]', lang)}]</span>
            </div>;
        })}
    </div>
}

interface Props {
    lang: MultiLang;
    catId: number;
    start: number;
}

interface State {
    loading: boolean;
    name: string | null;
}

class XwordsCategory extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: null,
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, catId, start } = this.props;
        if (prevProps.lang !== lang || prevProps.catId !== catId || prevProps.start !== start) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await XwordsUtils.initialize();
        if (this.isActive) {
            const name = XwordsUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, catId, start } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (null === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const ctitle = Functions.mlang(XwordsUtils.getCategoryName(catId), lang);
        if (ctitle === '') {
            return <PageNotFound lang={lang} />;
        }
        const title = catId === 0 ? Functions.mlang('[en]List of Categories[/en][ja]分類説明一覧[/ja]', lang) : Functions.mlang('[en]Category[/en][ja]用途別分類[/ja] : ', lang) + ctitle;
        const baseUrl = XwordsUtils.getCategoryUrl(catId);
        const result = {
            total: XwordsUtils.getCountEntries(catId, ''),
            entries: XwordsUtils.getEntries(catId, '', start)
        };
        return (
            <div className="xwords">
                <Helmet>
                    <title>{title} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{title}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XwordsBreadcrumbs lang={lang} catId={catId} />
                <div className="row">
                    <XwordsCategoryFieldset lang={lang} />
                </div>
                <div className="row">
                    <XwordsInitialFieldset lang={lang} catId={catId} />
                </div>
                {catId === 0 && <CategoryList lang={lang} />}
                {catId !== 0 &&
                    <>
                        <div className="row two">
                            <XwordsRecentlyFieldset lang={lang} catId={catId} />
                            <XwordsRankingFieldset lang={lang} catId={catId} />
                        </div>
                        <h4 className="title">{Functions.mlang(XwordsUtils.getCategoryName(catId), lang)}</h4>
                        <XwordsEntries lang={lang} baseUrl={baseUrl} result={result} start={start} />
                    </>
                }
            </div>
        );
    }
}
export default XwordsCategory;
