import React from 'react';
import XoopsCode from '../../common/lib/XoopsCode';
import { MultiLang } from '../../config';
import Functions from '../../functions';

interface Props {
    lang: MultiLang;
    modulepath: string;
}

const contents = [
    {
        modulepath: '/modules/newdb1',
        title: '[en]Invertebrate Brain Gallery[/en][ja]無脊椎動物脳ギャラリー[/ja]',
        content: '<p>[en]Many invertebrate animals have evolved a rich variety of brains (head ganglia). Unusually large brains of squids and octopuses and small but sophisticated brains of arthropods, especially those of insects, are among the most fascinating brains created on earth by natural selection. Here, photographs of invertebrates of 6 phyla, 35 orders and 47 species and those of their central nervous systems are presented, with brief explanations of their characteristics. Enjoy seeing the pictures and consider evolution of brains in invertebrates. These images can be only used for research and education freely. Providers (Dr. Makoto Mizunami) have copyright for images and documents. [/en][ja]脳を持つ動物は脊椎動物だけではありません。多くの無脊椎動物は、実に多様な脳を発達させています。イカやタコの哺乳類に匹敵するほどの巨大な脳や、節足動物、特に昆虫の小さく精巧な脳は、自然選択が生み出した脳のなかでも最も興味深いものの１つと言えます。ここでは、6門、35目、47種の無脊椎動物とその脳および中枢神経系全体の取り出し標本の写真を展示し、解説しています。画像を見ながら、脳の進化について考えるとよいでしょう。 　これらの画像は、教育、研究のためには自由に利用することができます。なお、画像、文章の著作権は、提供者（北海道大学・水波誠）にあります。 [/ja]</p>',
    },
    {
        modulepath: '/modules/newdb2',
        title: '[en]Application software[/en][ja]アプリケーションソフトウェア[/ja]',
        content: '<p>Information and URL for application software can be used in the invertebrate neuroethology are presented.</p>',
    },
    {
        modulepath: '/modules/newdb3',
        title: '[en]Crayfish Brain and Neurons[/en][ja]ザリガニの脳と神経[/ja]',
        content: '<p>Experimental data, such as confocal images by laser scanning microscope and electrical responses to the pheromone stimulus are presented. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb5',
        title: '[en]Bombyx Brain and Neurons[/en][ja]カイコガの脳・神経細胞データ[/ja]',
        content: '<p>[en]Experimental data, such as confocal images by laser scanning microscope and electrical responses to the pheromone stimulus are presented. Providers (Prof. Ryohei Kanzaki et al., The University of Tokyo) have copyright for images and documents. [/en][ja]カイコガの脳および同定ニューロンの共焦点レーザ顕微鏡画像、フェロモン刺激に対する電気応答などの実測データを提供しています。一連のデータの著作権は、東京大学・神崎亮平が管理しています。（データ取得者はファイル名末尾の記号による。　se：関洋一　rf:福島亮太　sn:並木重宏　sw:和田賢　hi:Evan Hill　Ki：吉川 美都子） [/ja]</p>',
    },
    {
        modulepath: '/modules/newdb7',
        title: '[en]Movies and Images[/en][ja]無脊椎動物の写真と映像[/ja]',
        content: '<p>Movie and image data of invertebrate animals are presented by this database. It can be used for educational fields. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb8',
        title: '[en]Cockroach Brain and Neurons[/en][ja]ゴキブリの脳と神経[/ja]',
        content: '<p>Experimental data, such as confocal images by laser scanning microscope are presented. Providers (.......)have copyright for images and documents. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb9',
        title: '[en]Cricket Brain and Neurons[/en][ja]コオロギの脳と神経[/ja]',
        content: '<p>Experimental data, sch as confocal images by laser scanning microscope are preseunted. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb10',
        title: '[en]Invertebrate Sensory System[/en][ja]無脊椎動物の感覚器[/ja]',
        content: '<p>Scanning migrographs of inverbetrates of antenna. Sensory hairs for olfaction and taste are shown. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb112',
        title: '[en]Honeybee Brain and Neurons[/en][ja]ミツバチの脳と神経[/ja]',
        content: '<p>Experimental data, such as confocal images by laser scanning microscope and electrical responses to the pheromone stimulus are presented. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb12',
        title: '[en]Fly Brain and Neurons[/en][ja]ハエの脳と神経[/ja]',
        content: '<p>Experimental data, such as images of transmission electron microscope and fluorescence maicroscope are presented. Providers (*) have copyright for images and documents.</p>',
    },
    {
        modulepath: '/modules/newdb13',
        title: '[en]Glomerular Structures of Lepidopteran Insects[/en][ja]各種鱗翅目昆虫触角葉糸球体構造[/ja]',
        content: '[en]<p>Insects have a pair of the antenna. In lepidopteran insects there are several different types of sensillum on the antennae for olfaction (i.e., sensilla torichodea, sensilla basiconica, and sensilla coeloconica). Each sensillum contains olfactory receptor neurons (ORNs). Axons of the ORNs project to the first order olfactory center, the antennal lobe (AL). The AL consists of a dorsally located male specific macroglomerular complex (MGC) and ventrally located ordinary glomeruli (OG). Pheromonal information is processed in the MGC and general odor information is processed in the OGs. Glomerular structures in different lepidopteran insects are demonstrated.</p><p><a href="/modules/htmldocs/IVBPF/General/sensory-system.html">Pheromone and general odor processing</a></p>[/en][ja]<p>昆虫はわれわれヒトを含めた哺乳類に匹敵する，あるいはそれ以上の優れた嗅覚能力を持っています．嗅覚受容細胞(匂いセンサ）で受容された匂い情報は嗅覚系一次中枢において匂いの識別処理が行われます．哺乳類ではこの脳領域を嗅球(olfactory bulb)，昆虫では触角葉(antennal lobe)と言いいます．触角葉は糸球体といわれる多数の球状構造からできています．このデータベースで挙げた鱗翅目昆虫の糸球体は，触角葉の入り口にある大きい糸球体（大糸球体(macroglomerular complex; MGC)）とその他の小さなたくさんの糸球体（常糸球体(ordinary glomerulus)）から構成されます．前者はフェロモン情報処理に，後者は植物や食物などの一般臭の情報を処理します．</p><p><a href="/modules/htmldocs/IVBPF/General/sensory-system.html">フェロモン及び一般臭受容の詳細</a>　<a href="/modules/htmldocs/IVBPF/General/general_odor_processing.html">匂い識別機構の詳細</a></p>[/ja]',
    },
    {
        modulepath: '/modules/noneLectClass',
        title: '中高教員対象研修会',
        content: '動物の行動が発現するしくみについての講義をとおして、感覚・脳・行動の成り立ち（神経・脳の役割）を学び、カイコガの匂い源探索の行動実験により、感覚・脳・行動の機能を考え、推測していきます。さらに、筋電位を計測する安価な増幅器を作製し、実際に作製した増幅器を使って、昆虫の羽ばたきをおこす筋肉の電位を計測することで、成体がつくる生物電気について学びます。また、成体が発生する電気によりロボット（チョロQ)を動かすことで、生物と工学の接点について学び、ブレインマシンインターフェース（BMI)といわれる、脳信号で車椅子を操作する最新の技術への取っ掛かりを実験によって体験します。'
    }
];

const CustomBlock: React.FC<Props> = (props: Props) => {
    const { lang, modulepath } = props;
    const data = contents.find((value) => modulepath === value.modulepath);
    if (typeof data === 'undefined') {
        return null;
    }
    return <div className="block">
        <div className="blockTitle">{Functions.mlang(data.title, lang)}</div>
        <div className="blockContent"><XoopsCode lang={lang} text={data.content} dohtml={true} /></div>
    </div>;
}

export default CustomBlock;
