import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import LangFlag from '../common/lib/LangFlag';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import imageEnglish from './assets/images/mlang_english.png';
import imageJapanese from './assets/images/mlang_japanese.png';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    keyword: string;
    menuid: number;
}

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            keyword: '',
            menuid: 0
        };
    }

    setMenuId(menuid: number) {
        this.setState({ menuid });
    }

    render() {
        const { lang } = this.props;
        return (
            <header id="header">
                <div className="clearfix">
                    <Link className="logo" to="/"><span className="hidden">{Config.SITE_TITLE}</span></Link>
                    <div className="utility">
                        <LangFlag lang="en" image={imageJapanese} /> <LangFlag lang="ja" image={imageEnglish} />
                    </div>
                </div>
                <nav className="menubar">
                    <ul className="mainmenu">
                        <li><Link to="/">{Functions.mlang('[en]HOME[/en][ja]ホーム[/ja]', lang)}</Link></li>
                        <li onMouseEnter={() => this.setMenuId(1)} onMouseLeave={() => this.setMenuId(0)}><Link to="/modules/htmldocs/IVBPF/Database/ivbpf.html">+ {Functions.mlang('[en]DATABASE[/en][ja]データベース[/ja]', lang)}</Link>
                            {1 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/newdb10/list.php?id=2">{Functions.mlang('[en]Invertebrte Sensory System[/en][ja]無脊椎動物の感覚器[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb1/list.php?id=2">{Functions.mlang('[en]Invertebrate Brain Gallery[/en][ja]無脊椎動物脳ギャラリー[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb5/list.php?id=2">{Functions.mlang('[en]Silkmoth Brain and Neurons[/en][ja]カイコガの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/BombyxStandardBrain/index.html">{Functions.mlang('[en]Silkmoth Standard Brain[/en][ja]カイコガ標準脳[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb112/list.php?id=2">{Functions.mlang('[en]Honeybee Brain and Neurons[/en][ja]ミツバチの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb9/list.php?id=2">{Functions.mlang('[en]Cricket Brain and Neurons[/en][ja]コオロギの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb12/list.php?id=2">{Functions.mlang('[en]Fly Brain and Neurons[/en][ja]ハエの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb8/list.php?id=2">{Functions.mlang('[en]Cockroach Brain and Neurons[/en][ja]ゴキブリの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb3/list.php?id=2">{Functions.mlang('[en]Crayfish Brain and Neurons[/en][ja]ザリガニの脳と神経[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb7/list.php?id=2">{Functions.mlang('[en]Invertebrate Image and Movie[/en][ja]無脊椎動物の画像と映像[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb13/list.php?id=2">{Functions.mlang('[en]Lepidoptera Antennal Lobe[/en][ja]各種鱗翅目昆虫触角葉糸球体構造[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/newdb2/list.php?id=2">{Functions.mlang('[en]Application Software[/en][ja]アプリケーションソフトウェア[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/PubMedPDF/journal.php?journal=266">{Functions.mlang('[en]Conference Posters[/en][ja]研究発表ポスター[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Database/Experimental_set_ups.html">{Functions.mlang('[en]Measurement Instruments[/en][ja]計測装置の製作方法[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Education/education.html">{Functions.mlang('[en]Documents for Student Experiments[/en][ja]生物教育と実習テキスト[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/PubMedPDF/">{Functions.mlang('[en]Reference and Textbooks[/en][ja]文献と教科書[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(2)} onMouseLeave={() => this.setMenuId(0)}><Link to="/modules/htmldocs/IVBPF/Science/insect_nervous_system.html">+ {Functions.mlang('[en]RESEARCH[/en][ja]研 究[/ja]', lang)}</Link>
                            {2 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Science/sensory-system.html">{Functions.mlang('[en]Sensory systems of insect[/en][ja]昆虫の感覚系[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/General/Insects_nervous_systems.html">{Functions.mlang('[en]Central nervous system of insects[/en][ja]昆虫の脳神経系[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/General/behavior.html">{Functions.mlang('[en]Animal and insect behavior[/en][ja]動物と昆虫の行動[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/General/methods.html">{Functions.mlang('[en]Methods for investigating neurons[/en][ja]昆虫のニューロンの調べ方[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Latest_topics/insect_robot_hybrid_system.html">{Functions.mlang('[en]Insect machine hybrid system[/en][ja]昆虫−ロボット融合システム(サイボーグ昆虫)[/ja]', lang)}</Link>
                                    {'ja' === lang &&
                                        <Link className="item" to="/modules/htmldocs/IVBPF/Science/multicompartment_model.html">単一ニューロンのモデル化</Link>
                                    }
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Latest_topics/supercom.html">{Functions.mlang('[en]Reconstructing an insect brain using a next-generation super computer[/en][ja]昆虫脳を再構成する：次世代スーパーコンピュータ[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/kei/index.html">{Functions.mlang('[en]Resources of IOSSIM[/en][ja]IOSSIMの公開ページ[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Latest_topics/behavior_control.html">{Functions.mlang('[en]External control of insect behavior and neurorehabilitation[/en][ja]昆虫行動制御・神経回路修復[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Science/comparative-physiology.html">{Functions.mlang('[en]Comparative table of invertebrate[/en][ja]無脊椎動物脳比較表[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(3)} onMouseLeave={() => this.setMenuId(0)}><Link to="/modules/htmldocs/IVBPF/Engineering/Engineering_contents.html">+ {Functions.mlang('[en]APPLICATION[/en][ja]機能利用[/ja]', lang)}</Link>
                            {3 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Spider_strings.html">{Functions.mlang('[en]Materials: Spider Silk[/en][ja]材料：クモの糸[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_kutikura.html">{Functions.mlang('[en]Materials: Insect Cuticle[/en][ja]材料：クチクラ[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_wing_based_joint_robot.html">{Functions.mlang('[en]Materials: Insect Wing[/en][ja]材料：昆虫の翅の柔軟なロボット関節への応用[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/odor_sensor.html">{Functions.mlang('[en]Sensor: Odor Sensor[/en][ja]感覚システム：匂いセンサ[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/model_based_robot.html">{Functions.mlang('[en]Sensor: Odor-source Searching Robot[/en][ja]感覚システム：匂い源探索ロボット[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Artificial_sensor.html">{Functions.mlang('[en]Sensor: Flow sensor[/en][ja]感覚システム：人工感覚毛センサ[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Ant_based_optic_navigation_robot.html">{Functions.mlang('[en]Sensor: Visual navigation in the deseart ant[/en][ja]感覚システム：サバクアリの視覚ナビゲーション[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Cricket_sound_orientation_robot.html">{Functions.mlang('[en]Sensor: Sound orientation in the cricket[/en][ja]感覚システム：コオロギによる音源定位[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Fly_based_flight_robot.html">{Functions.mlang('[en]Robot control: A visually guided flight robot[/en][ja]ロボット制御：ハエにヒントを得た視覚誘導飛行ロボット[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Ocellar_based_flight_controller.html">{Functions.mlang('[en]Robot control: A flight-stabilization device based on ocelli[/en][ja]ロボット制御：単眼による飛行安定装置[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_controlled_robot.html">{Functions.mlang('[en]Robot control: Insect controlled robot[/en][ja]ロボット制御：昆虫が操縦するロボット[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_based_flight_robot.html">{Functions.mlang('[en]Robot control: An insect-based flying robot[/en][ja]ロボット制御：昆虫による飛行関連ロボット[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_based_walking_robot.html">{Functions.mlang('[en]Robot control: Insect walking and a walking-controlled robot[/en][ja]ロボット制御：昆虫の歩行と歩行制御ロボット[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/biobot.html">{Functions.mlang('[en]Biobot[/en][ja]昆虫行動制御：昆虫の行動を遠隔で制御する[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Engineering/Insect_based_construction_method.html">{Functions.mlang('[en]An architectural insecticide method based on insect traits[/en][ja]建築的防虫法：昆虫の習性に基づく建築的防虫法[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(4)} onMouseLeave={() => this.setMenuId(0)}><Link to="/modules/htmldocs/IVBPF/Education/education.html">+ {Functions.mlang('[en]EDUCATION[/en][ja]教 育[/ja]', lang)}</Link>
                            {4 === this.state.menuid && 'ja' === lang &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/htmldocs/IVBPF/outreach/index.html">小中高生を対象としたサイエンス教室</Link>
                                    <Link className="item" to="/modules/noneLectClass/">中高教員対象研修会</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Education/experiments/index.html">中高教員から提供された生物実習テキスト</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/outreach/museum.html">大学・博物館からの資料提供による脳ギャラリーの作成</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Education/Experimental_set_ups.html">計測装置の作成法</Link>
                                </div>
                            }
                        </li>
                        <li onMouseEnter={() => this.setMenuId(5)} onMouseLeave={() => this.setMenuId(0)}><span>+ {Functions.mlang('[en]REFERENCE[/en][ja]リファレンス[/ja]', lang)}</span>
                            {5 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/xwords/index.php">{Functions.mlang('[en]Glossary[/en][ja]用語集[/ja]', lang)}</Link>
                                    <Link className="item" to="/modules/htmldocs/IVBPF/Science/comparative-physiology.html">{Functions.mlang('[en]Comparative Table[/en][ja]無脊椎動物脳比較表[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>

                        <li onMouseEnter={() => this.setMenuId(6)} onMouseLeave={() => this.setMenuId(0)}><span>+ {Functions.mlang('[en]INFORMATION[/en][ja]情 報[/ja]', lang)}</span>
                            {6 === this.state.menuid &&
                                <div className="submenu">
                                    <Link className="item" to="/modules/credits/index.php">{Functions.mlang('[en]About This Site[/en][ja]このサイトについて[/ja]', lang)}</Link>
                                </div>
                            }
                        </li>
                    </ul>
                </nav>
            </header>
        );

    }
}

export default withRouter(Header);
