import React from 'react';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import PicoContext from './lib/PicoContext';
import PicoCategory from './PicoCategory';
import PicoContent from './PicoContent';
import PicoMenu from './PicoMenu';

interface Props {
    lang: MultiLang;
    context: PicoContext;
}

const PicoIndex = (props: Props) => {
    const { lang, context } = props;
    const pico = context.getModule();
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    if (pico.show_menuinmoduletop !== 0) {
        return <PicoMenu lang={lang} context={context} />
    }
    if (pico.show_listasindex !== 0) {
        return <PicoCategory lang={lang} context={context} catId={0} />
    }
    const content = context.getFirstContent(0);
    if (content === null) {
        return <PageNotFound lang={lang} />;
    }
    return <PicoContent lang={lang} context={context} contentId={content.id} />;

}

export default PicoIndex;
