import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import PubmedPdfAuthorsByInitial from './PubmedPdfAuthorsByInitial';
import PubmedPdfIndex from './PubmedPdfIndex';
import PubmedPdfJournalsByYear from './PubmedPdfJournalsByYear';
import PubmedPdfPaperDesc from './PubmedPdfPaperDesc';
import PubmedPdfPapersByAuthor from './PubmedPdfPapersByAuthor';
import PubmedPdfPapersByJournal from './PubmedPdfPapersByJournal';
import PubmedPdfPapersByYear from './PubmedPdfPapersByYear';

interface Props {
    lang: MultiLang;
}

const PubmedPdf = (props: Props) => {
    const { lang } = props;
    const pathname = '/modules/PubMedPDF';
    return (
        <>
            <Switch>
                <Route exact path={`${pathname}/index.php`} render={() => {
                    return <PubmedPdfIndex lang={lang} />;
                }} />
                <Route exact path={`${pathname}/author.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const initial = params.get('initial');
                    if (initial !== null && /^[A-Z]/.test(initial)) {
                        return <PubmedPdfAuthorsByInitial lang={lang} initial={initial} />;
                    }
                    const authorIdStr = params.get('author');
                    if (authorIdStr !== null && /^[0-9]+/.test(authorIdStr)) {
                        const authorId = parseInt(authorIdStr);
                        const yearStr = params.get('year');
                        const year = (yearStr !== null && /^[0-9]+/.test(yearStr)) ? parseInt(yearStr) : 0;
                        return <PubmedPdfPapersByAuthor lang={lang} authorId={authorId} year={year} />;
                    }
                    return <PageNotFound lang={lang} />
                }} />
                <Route exact path={`${pathname}/year.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const yearStr = params.get('year');
                    const journalIdStr = params.get('journal');
                    if (yearStr === null || !(/^[0-9]+/.test(yearStr)) || (journalIdStr !== null && !(/^[0-9]+/.test(journalIdStr)))) {
                        return <PageNotFound lang={lang} />;
                    }
                    const year = parseInt(yearStr);
                    const journalId = journalIdStr !== null ? parseInt(journalIdStr) : 0;
                    if (journalId === 0) {
                        return <PubmedPdfJournalsByYear lang={lang} year={year} />;
                    }
                    return <PubmedPdfPapersByYear lang={lang} year={year} journalId={journalId} />;
                }} />
                <Route exact path={`${pathname}/journal.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const journalIdStr = params.get('journal');
                    const yearStr = params.get('year');
                    if (journalIdStr === null || !(/^[0-9]+/.test(journalIdStr)) || (yearStr !== null && !(/^[0-9]+/.test(yearStr)))) {
                        return <PageNotFound lang={lang} />
                    }
                    const journalId = parseInt(journalIdStr);
                    const year = yearStr !== null ? parseInt(yearStr) : 0;
                    return <PubmedPdfPapersByJournal lang={lang} journalId={journalId} year={year} />;
                }} />
                <Route exact path={`${pathname}/paper_desc.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const idStr = params.get('id');
                    if (idStr === null || !(/^[0-9]+/.test(idStr))) {
                        return <PageNotFound lang={lang} />
                    }
                    const id = parseInt(idStr);
                    return <PubmedPdfPaperDesc lang={lang} id={id} />;
                }} />
                <Route exact path={pathname} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const url = pathname + '/index.php' + location.search + location.hash;
                    return <Redirect to={url} />;
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default PubmedPdf;
