import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import XwordsUtils from './XwordsUtils';

interface Props {
    lang: MultiLang;
    catId: number;
}

const XwordsRecentlyFieldset: React.FC<Props> = (props: Props) => {
    const { lang, catId } = props;
    const recent = XwordsUtils.getRecentlyEntries(catId);
    const title = catId === 0 ? '[en]New Arrival[/en][ja]新着[/ja]' : '[en]New Arrival in this Category[/en][ja]この分野での新着[/ja]';
    return <fieldset className="item recently">
        <legend className="title">{Functions.mlang(title, lang)}</legend>
        <div className="body">
            <ul>
                {recent.map((entry) => {
                    const date = Functions.formatDate(entry.timestamp, 'YYYY/M/D');
                    return <li key={entry.id}><Link to={XwordsUtils.getEntryUrl(entry.id)}>{Functions.mlang(entry.term, lang)}</Link> <span className="info">[{date}]</span></li>
                })}
            </ul>
        </div>
    </fieldset>;
}

export default XwordsRecentlyFieldset;
