import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/xwords.css';
import XwordsBreadcrumbs from './lib/XwordsBreadcrumbs';
import XwordsCategoryFieldset from './lib/XwordsCategoryFieldset';
import XwordsInitialFieldset from './lib/XwordsInitialFieldset';
import XwordsUtils from './lib/XwordsUtils';

interface Props {
    lang: MultiLang;
    entryId: number;
}

interface State {
    loading: boolean;
    name: string | null;
}

class XwordsEntry extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: null,
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, entryId } = this.props;
        if (prevProps.lang !== lang || prevProps.entryId !== entryId) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await XwordsUtils.initialize();
        if (this.isActive) {
            const name = XwordsUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, entryId } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (null === name) {
            return <PageNotFound lang={lang} />;
        }
        const entry = XwordsUtils.getEntry(entryId);
        if (null == entry) {
            return <PageNotFound lang={lang} />;
        }

        const mtitle = Functions.mlang(name, lang);
        const title = Functions.mlang(entry.term, lang);
        const date = lang === 'en' ? Functions.formatDate(entry.timestamp, 'MMMM Do, YYYY') : Functions.formatDate(entry.timestamp, 'YYYY年M月D日');

        return (
            <div className="xwords">
                <Helmet>
                    <title>{title} - {mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{title}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XwordsBreadcrumbs lang={lang} catId={entry.cat_id} initial={entry.initial} entryId={entry.id} />
                <div className="row">
                    <XwordsCategoryFieldset lang={lang} />
                </div>
                <div className="row">
                    <XwordsInitialFieldset lang={lang} catId={entry.cat_id} />
                </div>
                <div className="entry">
                    <h4 className="title">{Functions.mlang(title, lang)}</h4>
                    <div className="description">
                        <div className="yomi">{Functions.mlang('[en]Yomi[/en][ja]読み[/ja] : ' + entry.yomi, lang)}</div>
                        <div className="definition">{Functions.mlang(entry.definition, lang)}</div>
                        <div className="note">
                            <span className="date">{Functions.mlang('[en]Date[/en][ja]投稿日[/ja]', lang)} : {date}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default XwordsEntry;
