import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import XoopsPathRedirect from '../common/XoopsPathRedirect';
import { MultiLang } from '../config';
import CosmoDb from '../cosmodb/CosmoDb';
import Credits from '../credits/Credits';
import Pico from '../pico/Pico';
import PubmedPdf from '../pubmedpdf/PubmedPdf';
import Xwords from '../xwords/Xwords';
import CustomBlock from './blocks/CustomBlock';
import Footer from './Footer';
import Header from './Header';

interface Props {
    lang: MultiLang;
}

const Page: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const url = '/modules/htmldocs/IVBPF/Top/index.html';
    return <div id="page">
        <Header lang={lang} />
        <div id="main">
            <Switch>
                <Route path="/modules/newdb1" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb1" />} />
                <Route path="/modules/newdb2" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb2" />} />
                <Route path="/modules/newdb3" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb3" />} />
                <Route path="/modules/newdb5" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb5" />} />
                <Route path="/modules/newdb7" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb7" />} />
                <Route path="/modules/newdb8" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb8" />} />
                <Route path="/modules/newdb9" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb9" />} />
                <Route path="/modules/newdb10" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb10" />} />
                <Route path="/modules/newdb112" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb112" />} />
                <Route path="/modules/newdb12" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb12" />} />
                <Route path="/modules/newdb13" render={() => <CustomBlock lang={lang} modulepath="/modules/newdb13" />} />
                <Route path="/modules/noneLectClass" render={() => <CustomBlock lang={lang} modulepath="/modules/noneLectClass" />} />
            </Switch>
            <div className="mainContent">
                <Switch>
                    <Route exact path="/" render={() => <Redirect to={url} />} />
                    <Route path="/modules/htmldocs/index.html" render={() => <Redirect to={url} />} />
                    <Route path="/modules/htmldocs/IVBPF/index.html" render={() => <Redirect to={url} />} />
                    <Route path="/modules/htmldocs" render={() => <Pico lang={lang} modulepath="/modules/htmldocs" showTitle={false} />} />
                    <Route path="/modules/documents" render={() => <Pico lang={lang} modulepath="/modules/documents" showTitle={false} />} />
                    <Route path="/modules/newdb1" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb1" />} />
                    <Route path="/modules/newdb2" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb2" />} />
                    <Route path="/modules/newdb3" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb3" />} />
                    <Route path="/modules/newdb5" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb5" />} />
                    <Route path="/modules/newdb7" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb7" />} />
                    <Route path="/modules/newdb8" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb8" />} />
                    <Route path="/modules/newdb9" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb9" />} />
                    <Route path="/modules/newdb10" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb10" />} />
                    <Route path="/modules/newdb112" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb112" />} />
                    <Route path="/modules/newdb12" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb12" />} />
                    <Route path="/modules/newdb13" render={() => <CosmoDb lang={lang} modulepath="/modules/newdb13" />} />
                    <Route path="/modules/credits" render={() => <Credits lang={lang} />} />
                    <Route path="/modules/PubMedPDF" render={() => <PubmedPdf lang={lang} />} />
                    <Route path="/modules/xwords" render={() => <Xwords lang={lang} />} />
                    <Route path="/modules/noneLectClass" render={() => <div>神生研教員研修: <a href="http://www.brain.rcast.u-tokyo.ac.jp/others/2010-jinseiken/100131-jinseiken-text.pdf">100131-jinseiken-text.pdf</a></div>} />
                    <Route component={XoopsPathRedirect} />
                </Switch>
            </div>
        </div>
        <Footer lang={lang} />
    </div>;
}

export default Page;
