import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/pubmedpdf.css';
import PubmedPdfUtils from './lib/PubmedPdfUtils';


interface Props {
    lang: MultiLang;
}

interface State {
    loading: boolean;
    name: string;
}

class PubmedPdfIndex extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang } = this.props;
        if (prevProps.lang !== lang) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await PubmedPdfUtils.initialize();
        if (this.isActive) {
            const name = PubmedPdfUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const authorInitials = PubmedPdfUtils.getAuthorInitials();
        const years = PubmedPdfUtils.getYears();
        const journalInitials = PubmedPdfUtils.getJournalInitials();
        return (
            <div className="pubmedpdf" >
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <nav className="header">
                    <div className="count">
                        <strong>{PubmedPdfUtils.getCountPapers()}</strong> papers registered
                    </div>
                    <div className="link">
                        <a href="https://www.ncbi.nlm.nih.gov/pubmed" target="_blank" rel="noopener noreferrer">[PubMed]</a>
                    </div>
                </nav>
                <h4 className="pt">{Functions.mlang('[en]Author[/en][ja]著者[/ja]', lang)}</h4>
                <div className="initials">
                    {authorInitials.map((initial) => {
                        return <span className="initial" key={initial}><Link to={PubmedPdfUtils.getAuthorInitialUrl(initial)}>{initial}</Link></span>
                    })}
                </div>
                <h4 className="pt">{Functions.mlang('[en]Year[/en][ja]発行年[/ja]', lang)}</h4>
                <div className="years">
                    {years.map((year) => {
                        return <div className="year" key={year.year}><Link to={PubmedPdfUtils.getYearUrl(year.year, 0)}>{year.year}</Link> ({year.count})</div>;
                    })}
                </div>
                <h4 className="pt">{Functions.mlang('[en]Journal/Book[/en][ja]ジャーナル／書籍[/ja]', lang)}</h4>
                <div className="journals">
                    {journalInitials.map((initial) => {
                        return <span className="initial" key={initial}><HashLink to={PubmedPdfUtils.getIndexUrl() + `#${initial}`}>{initial}</HashLink></span>
                    })}
                    {journalInitials.map((initial) => {
                        const journals = PubmedPdfUtils.getJournalsByInitial(initial);
                        return <div id={initial} className="journal-group" key={initial}>
                            <div className="title">{initial}</div>
                            {journals.map((journal) => {
                                const name = Functions.mlang(journal.name, lang);
                                const nameShort = name.substr(0, 20) + (name.length > 20 ? '...' : '');
                                return <div className="journal" key={journal.id}>
                                    <Link to={PubmedPdfUtils.getJournalUrl(journal.id, 0)} title={name}>{nameShort}</Link> ({journal.count})
                                </div>;
                            })}
                        </div>;
                    })}
                </div>
            </div>
        );
    }
}
export default PubmedPdfIndex;
