import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import CosmoDbContext, { CosmoDbListDataType } from './lib/CosmoDbContext';
import CosmoDbListListView from './lib/CosmoDbListListView';
import CosmoDbListPageNavi from './lib/CosmoDbListPageNavi';
import CosmoDbListThumbnailView from './lib/CosmoDbListThumbnailView';
import CosmoDbListViewSwitcher from './lib/CosmoDbListViewSwitcher';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    context: CosmoDbContext;
    params: URLSearchParams;
}

const CosmoDbList: React.FC<Props> = (props: Props) => {
    const { lang, context, params } = props;
    const paramId = params.get('id');
    if (paramId === null || !/^\d+$/.test(paramId)) {
        return <PageNotFound lang={lang} />;
    }
    const id = parseInt(paramId);
    let list = context.getList(id);
    if (list === null) {
        return <PageNotFound lang={lang} />;
    }
    const result = context.search(params);
    return <div className="list">
        <Helmet>
            <title>{Functions.mlang(context.getModuleName(), lang)} - {Functions.siteTitle(lang)}</title>
        </Helmet>
        <CosmoDbListViewSwitcher lang={lang} context={context} params={params} />
        <CosmoDbListPageNavi lang={lang} context={context} params={params} total={result.total} />
        <hr />
        {list.type === CosmoDbListDataType.List ?
            <CosmoDbListListView lang={lang} context={context} list={list} params={params} data={result.result} /> :
            <CosmoDbListThumbnailView lang={lang} context={context} list={list} params={params} data={result.result} />
        }
        <CosmoDbListPageNavi lang={lang} context={context} params={params} total={result.total} />
    </div>;
};

export default withRouter(CosmoDbList);
