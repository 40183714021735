import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/xwords.css';
import XwordsBreadcrumbs from './lib/XwordsBreadcrumbs';
import XwordsCategoryFieldset from './lib/XwordsCategoryFieldset';
import XwordsInitialFieldset from './lib/XwordsInitialFieldset';
import XwordsRandomFieldset from './lib/XwordsRandomFieldset';
import XwordsRankingFieldset from './lib/XwordsRankingFieldset';
import XwordsRecentlyFieldset from './lib/XwordsRecentlyFieldset';
import XwordsSearchFieldset from './lib/XwordsSearchFieldset';
import XwordsUtils from './lib/XwordsUtils';


interface Props {
    lang: MultiLang;
}

interface State {
    loading: boolean;
    name: string;
}

class XwordsIndex extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang } = this.props;
        if (prevProps.lang !== lang) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await XwordsUtils.initialize();
        if (this.isActive) {
            const name = XwordsUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        return (
            <div className="xwords">
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <XwordsBreadcrumbs lang={lang} />
                <div className="row">
                    <XwordsCategoryFieldset lang={lang} />
                </div>
                <div className="row">
                    <XwordsInitialFieldset lang={lang} catId={0} />
                </div>
                <div className="row three">
                    <XwordsRecentlyFieldset lang={lang} catId={0} />
                    <XwordsRankingFieldset lang={lang} catId={0} />
                    <XwordsRandomFieldset lang={lang} />
                </div>
                <div className="row">
                    <XwordsSearchFieldset lang={lang} type={4} catId={0} andor="OR" term="" />
                </div>
            </div>
        );
    }
}
export default XwordsIndex;
