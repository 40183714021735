import axios from 'axios';

export interface CreditsPageData {
    id: number;
    title: string;
}

export interface CreditsPageDetailData extends CreditsPageData {
    content: string;
    lastupdate: number;
}

export interface CreditsIndexData {
    name: string;
    pages: CreditsPageData[];
}

class CreditsUtils {

    private pathname: string;
    private index?: CreditsIndexData | null;

    constructor(path: string) {
        this.pathname = path;
    }

    getIndexUrl(): string {
        return this.pathname + '/';
    }

    getPageUrl(id: number): string {
        return this.getIndexUrl() + (0 === id ? 'aboutus.php' : ('?id=' + id));
    }

    async getIndex(): Promise<CreditsIndexData | null> {
        if (typeof this.index === 'undefined') {
            try {
                const url = this.getIndexUrl() + 'index.json';
                const response = await axios.get(url);
                const index = response.data as CreditsIndexData;
                this.index = index;
            } catch (err) {
                this.index = null;
            }
        }
        return this.index;
    }

    async getPage(id: number): Promise<CreditsPageDetailData | null> {
        let page = null;
        try {
            const url = this.getIndexUrl() + id + '.json';
            const response = await axios.get(url);
            page = response.data as CreditsPageDetailData;
        } catch (err) {
            // ignore
        }
        return page;
    }
}

export default new CreditsUtils('/modules/credits');
