import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../common/lib/Loading';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import './assets/pubmedpdf.css';
import PubmedPdfBreadcrumbs from './lib/PubmedPdfBreadcrumbs';
import PubmedPdfUtils, { PubmedPdfBreadcrumbsData } from './lib/PubmedPdfUtils';

interface Props {
    lang: MultiLang;
    year: number;
}

interface State {
    loading: boolean;
    name: string;
}

class PubmedPdfJournalsByYear extends Component<Props, State> {

    private isActive: boolean;

    constructor(props: Props) {
        super(props);
        this.isActive = false;
        this.state = {
            loading: true,
            name: '',
        };
    }

    componentDidMount() {
        this.isActive = true;
        this.updatePage();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang, year } = this.props;
        if (prevProps.lang !== lang || prevProps.year !== year) {
            this.updatePage();
        }
    }

    componentWillUnmount() {
        this.isActive = false;
    }

    async updatePage() {
        await PubmedPdfUtils.initialize();
        if (this.isActive) {
            const name = PubmedPdfUtils.getName();
            this.setState({ loading: false, name });
        }
    }

    render() {
        const { lang, year } = this.props;
        const { loading, name } = this.state;
        if (loading) {
            return <Loading />;
        }
        if ('' === name) {
            return <PageNotFound lang={lang} />;
        }
        const mtitle = Functions.mlang(name, lang);
        const journals = PubmedPdfUtils.getJournalsByYear(year);
        if (journals.length === 0) {
            return <PageNotFound lang={lang} />;
        }
        const breadcrumbs: PubmedPdfBreadcrumbsData[] = [
            { id: `y.${year}`, name: `${year}`, link: PubmedPdfUtils.getYearUrl(year, 0) }
        ];
        return (
            <div className="pubmedpdf">
                <Helmet>
                    <title>{mtitle} - {Functions.siteTitle(lang)}</title>
                </Helmet>
                <h3>{mtitle}</h3>
                <NoticeSiteHasBeenArchived lang={lang} />
                <PubmedPdfBreadcrumbs lang={lang} breadcrumbs={breadcrumbs} />
                <hr />
                <div className="journals">
                    <div className="journal-group">
                        {journals.map((journal) => {
                            const name = Functions.mlang(journal.name, lang);
                            const nameShort = name.substr(0, 20) + (name.length > 20 ? '...' : '');
                            return <div className="journal" key={journal.id}>
                                <Link to={PubmedPdfUtils.getYearUrl(year, journal.id)} title={name}>{nameShort}</Link> ({journal.count})
                            </div>;
                        })}
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default PubmedPdfJournalsByYear;
