import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import XwordsCategory from './XwordsCategory';
import XwordsEntry from './XwordsEntry';
import XwordsIndex from './XwordsIndex';
import XwordsLetter from './XwordsLetter';
import XwordsSearch from './XwordsSearch';

interface Props {
    lang: MultiLang;
}

const Xwords = (props: Props) => {
    const { lang } = props;
    const pathname = '/modules/xwords';
    return (
        <>
            <Switch>
                <Route exact path={`${pathname}/index.php`} render={() => {
                    return <XwordsIndex lang={lang} />;
                }} />
                <Route exact path={`${pathname}/category.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const catIdStr = params.get('categoryID') || '0';
                    const catId = /^[0-9]+$/.test(catIdStr) ? parseInt(catIdStr) : 0;
                    const startStr = params.get('start') || '0';
                    const start = /^[0-9]+$/.test(startStr) ? parseInt(startStr) : 0;
                    return <XwordsCategory lang={lang} catId={catId} start={start} />;
                }} />
                <Route exact path={`${pathname}/letter.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const catIdStr = params.get('categoryID') || '0';
                    const catId = /^[0-9]+$/.test(catIdStr) ? parseInt(catIdStr) : 0;
                    const initial = params.get('init') || '';
                    const startStr = params.get('start') || '0';
                    const start = /^[0-9]+$/.test(startStr) ? parseInt(startStr) : 0;
                    return <XwordsLetter lang={lang} catId={catId} initial={initial} start={start} />;
                }} />
                <Route exact path={`${pathname}/entry.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const entryIdStr = params.get('entryID') || '0';
                    const entryId = /^[0-9]+$/.test(entryIdStr) ? parseInt(entryIdStr) : 0;
                    return <XwordsEntry lang={lang} entryId={entryId} />;
                }} />
                <Route exact path={`${pathname}/search.php`} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const params = new URLSearchParams(location.search);
                    const typeStr = params.get('type') || '4';
                    const type = /^[0-9]+$/.test(typeStr) ? parseInt(typeStr) : 0;
                    const catIdStr = params.get('catID') || '0';
                    const catId = /^[0-9]+$/.test(catIdStr) ? parseInt(catIdStr) : 0;
                    const andor = params.get('andor') || 'OR';
                    const term = params.get('term') || '';
                    const startStr = params.get('start') || '0';
                    const start = /^[0-9]+$/.test(startStr) ? parseInt(startStr) : 0;
                    return <XwordsSearch lang={lang} type={type} catId={catId} andor={andor} term={term} start={start} />
                }} />
                <Route exact path={pathname} render={(props: RouteComponentProps) => {
                    const { location } = props;
                    const url = pathname + '/index.php' + location.search + location.hash;
                    return <Redirect to={url} />
                }} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default Xwords;
