import axios from 'axios';
import loki from 'lokijs';

export interface XwordsCategoryData {
    id: number;
    name: string;
    total: number;
}

export interface XwordsEntryData {
    id: number;
    cat_id: number;
    term: string;
    yomi: string;
    initial: string;
    definition: string;
    timestamp: number;
    counter: number;
}

export interface XwordsInitialData {
    [key: string]: number
}

export interface XwordsIndexData {
    name: string;
    category: XwordsCategoryData[];
    entry: XwordsEntryData[];
}

export interface XwordsGeneralInfo {
    name: string;
    category: XwordsCategoryData[];
    initial: XwordsInitialData;
}

export interface XwordsIndexPageData {
    name: string;
    category: XwordsCategoryData[];
    initial: XwordsInitialData;
    recent: XwordsEntryData[];
    ranking: XwordsEntryData[];
    random: XwordsEntryData | null;
}

export interface XwordsSearchResult {
    total: number;
    entries: XwordsEntryData[];
}

export const XWORDS_INITIAL_KEYS = [
    { key: 'A', label: 'A' },
    { key: 'B', label: 'B' },
    { key: 'C', label: 'C' },
    { key: 'D', label: 'D' },
    { key: 'E', label: 'E' },
    { key: 'F', label: 'F' },
    { key: 'G', label: 'G' },
    { key: 'H', label: 'H' },
    { key: 'I', label: 'I' },
    { key: 'J', label: 'J' },
    { key: 'K', label: 'K' },
    { key: 'L', label: 'L' },
    { key: 'M', label: 'M' },
    { key: 'N', label: 'N' },
    { key: 'O', label: 'O' },
    { key: 'P', label: 'P' },
    { key: 'Q', label: 'Q' },
    { key: 'R', label: 'R' },
    { key: 'S', label: 'S' },
    { key: 'T', label: 'T' },
    { key: 'U', label: 'U' },
    { key: 'V', label: 'V' },
    { key: 'W', label: 'W' },
    { key: 'X', label: 'X' },
    { key: 'Y', label: 'Y' },
    { key: 'Z', label: 'Z' },
    { key: '01', label: 'あ行' },
    { key: '02', label: 'か行' },
    { key: '03', label: 'さ行' },
    { key: '04', label: 'た行' },
    { key: '05', label: 'な行' },
    { key: '06', label: 'は行' },
    { key: '07', label: 'ま行' },
    { key: '08', label: 'や行' },
    { key: '09', label: 'ら行' },
    { key: '10', label: 'わ・ん' },
    { key: '11', label: '[en]OHTER[/en][ja]その他[/ja]' },
    { key: '', label: '[en]ALL[/en][ja]すべて[/ja]' }
];

class XwordsUtils {

    private isInitialized: boolean;
    private pathname: string;
    private database: loki;
    private name: string;
    private categories: Collection<XwordsCategoryData>;
    private entries: Collection<XwordsEntryData>;

    constructor(path: string) {
        this.isInitialized = false;
        this.pathname = path;
        this.database = new loki(path);
        this.name = '';
        this.categories = this.database.addCollection('categories');
        this.entries = this.database.addCollection('entries');
    }

    getIndexUrl(): string {
        return this.pathname + '/index.php';
    }

    getCategoryUrl(id: number): string {
        return this.pathname + '/category.php' + (0 === id ? '' : ('?categoryID=' + id));
    }

    getLetterUrl(initial: string, catId: number): string {
        let url = this.pathname + '/letter.php?init=' + initial;
        if (catId !== 0) {
            url += '&categoryID=' + catId;
        }
        return url;
    }

    getEntryUrl(id: number): string {
        return this.pathname + '/entry.php?entryID=' + id;
    }

    getSearchUrl(type: number, catID: number, andor: string, term: string): string {
        const params = new URLSearchParams();
        params.set('type', type.toString());
        params.set('catID', catID.toString());
        params.set('andor', andor);
        params.set('term', term);
        return this.pathname + '/search.php?' + params.toString();
    }

    getName(): string {
        return this.name;
    }

    getCategory(catId: number): XwordsCategoryData | null {
        return this.categories.findOne({ id: catId });
    }

    getEntry(entryId: number): XwordsEntryData | null {
        return this.entries.findOne({ id: entryId });
    }

    getCategoryName(catId: number): string {
        if (catId === 0) {
            return '[en]ANY[/en][ja]全分類[/ja]';
        }
        const category = this.getCategory(catId);
        return category !== null ? category.name : '';
    }

    getInitialName(initial: string): string {
        const result = XWORDS_INITIAL_KEYS.find((value) => { return value.key === initial; });
        return typeof result !== 'undefined' ? result.label : '';
    }

    getEntryName(entryId: number): string {
        const entry = this.getEntry(entryId);
        return entry !== null ? entry.term : '';
    }

    getCategories(): XwordsCategoryData[] {
        return this.categories.find();
    }

    getCountInitials(catId: number): XwordsInitialData {
        let initial: { [key: string]: number } = {};
        let total = 0;
        XWORDS_INITIAL_KEYS.forEach((value) => {
            const filter: any = { initial: value.key };
            if (catId !== 0) {
                filter.cat_id = catId;
            }
            const count = this.entries.chain().find(filter).count();
            initial[value.key] = count;
            total += count;
        });
        initial[''] = total;
        return initial;
    }

    getCountEntries(catId: number, initial: string): number {
        const filter: any = {};
        if (initial !== '') {
            filter.initial = initial;
        }
        if (catId !== 0) {
            filter.cat_id = catId;
        }
        return this.entries.chain().find(filter).count();
    }

    getRandomEntry(): XwordsEntryData | null {
        const total = this.entries.count();
        const random = this.entries.chain().find().offset(Math.floor(Math.random() * total)).limit(1).data();
        return random.length === 0 ? null : random[0];
    }

    getRecentlyEntries(catId: number): XwordsEntryData[] {
        const filter: any = {};
        if (catId !== 0) {
            filter.cat_id = catId;
        }
        return this.entries.chain().find(filter).simplesort('timestamp', true).limit(5).data();
    }

    getRankingEntries(catId: number): XwordsEntryData[] {
        const filter: any = {};
        if (catId !== 0) {
            filter.cat_id = catId;
        }
        return this.entries.chain().find(filter).simplesort('counter', true).limit(5).data();
    }

    getEntries(catId: number, initial: string, start: number): XwordsEntryData[] {
        const filter: any = {};
        if (initial !== '') {
            filter.initial = initial;
        }
        if (catId !== 0) {
            filter.cat_id = catId;
        }
        return this.entries.chain().find(filter).simplesort('yomi').offset(start).limit(20).data();
    }

    search(type: number, catId: number, andor: string, term: string, start: number): XwordsSearchResult {
        const genRegexFilter = (term: string) => {
            const regex = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            return { '$regex': [regex, 'i'] };
        }
        const genConditionFilter = (andor: string, term: string) => {
            if (andor === 'EXACT') {
                return { '$eq': term.trim() };
            } else if (andor !== 'AND' && andor !== 'OR') {
                return null;
            }
            const terms = term.split(/(\s+)/).map((term) => term.trim()).filter((term) => term.length > 0);
            if (terms.length === 0) {
                return null;
            } else if (terms.length === 1) {
                return genRegexFilter(terms.pop() || '');
            }
            const condition = andor === 'AND' ? '$and' : '$or';
            return { [condition]: terms.map((term) => genRegexFilter(term)) }
        }
        const genTypeFilter = (type: number, andor: string, term: string) => {
            const filter: any = { '$or': [] };
            const conditionFilter = genConditionFilter(andor, term);
            if (conditionFilter === null) {
                return null;
            }
            switch (type) {
                case 1:
                    filter['$or'].push({ term: conditionFilter });
                    break;
                case 2:
                    filter['$or'].push({ yomi: conditionFilter });
                    break;
                case 3:
                    filter['$or'].push({ definition: conditionFilter });
                    break;
                case 4:
                    filter['$or'].push({ term: conditionFilter });
                    filter['$or'].push({ yomi: conditionFilter });
                    filter['$or'].push({ definition: conditionFilter });
                    break;
                default:
                    return null;
            }
            return filter;
        }
        const genCatIdFilter = (catId: number, type: number, andor: string, term: string) => {
            const typeFilter = genTypeFilter(type, andor, term);
            if (typeFilter === null) {
                return null;
            }
            const filter = { '$and': [typeFilter] };
            if (catId !== 0) {
                filter['$and'].push({ '$eq': { cat_id: catId } });
            }
            return filter;
        }
        const filter = genCatIdFilter(catId, type, andor, term);
        if (filter === null) {
            return { total: 0, entries: [] };
        }
        const result = this.entries.chain().find(filter);
        return {
            total: result.count(),
            entries: result.simplesort('yomi').offset(start).limit(20).data()
        };
    }

    async initialize(): Promise<boolean> {
        if (!this.isInitialized) {
            try {
                const url = this.pathname + '/index.json';
                const response = await axios.get(url);
                const index = response.data as XwordsIndexData;
                this.name = index.name;
                index.category.forEach((value) => {
                    this.categories.insert(value);
                });
                index.entry.forEach((value) => {
                    this.entries.insert(value);
                });
            } catch (err) {
                // ignore
            }
            this.isInitialized = true;
        }
        return this.isInitialized;
    }

}

export default new XwordsUtils('/modules/xwords');
