import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';

interface Props {
    lang: MultiLang;
    baseUrl: string;
    total: number;
    start: number;
}

const XwordsEntriesPageNavi: React.FC<Props> = (props: Props) => {
    const { baseUrl, total, start } = props;
    if (total <= 20 || start > total) {
        return null;
    }
    const now = Math.ceil((start + 1) / 20);
    const min = 1;
    const max = Math.ceil(total / 20);
    const prev = now === 1 ? 0 : now - 1;
    const next = now === max ? 0 : now + 1;
    let rangeMin = now - 3;
    if (rangeMin < 2) {
        rangeMin = 2;
    }
    let rangeMax = now + 3;
    if (rangeMax > max - 1) {
        rangeMax = max - 1;
    }
    const link = (num: number, el?: JSX.Element) => {
        const label = el || num;
        const url = baseUrl + (num - 1 > 0 ? ('&start=' + ((num - 1) * 20)) : '');
        return num === now ? <>({label})</> : <Link to={url}>{label}</Link>
    }
    const items: JSX.Element[] = [];
    if (prev !== 0) {
        items.push(link(prev, <>&laquo;</>));
    }
    items.push(link(min));
    if (now - min > 4) {
        items.push(<>...</>);
    }
    for (let range = rangeMin; range <= rangeMax; range++) {
        items.push(link(range));
    }
    if (max - now > 4) {
        items.push(<>...</>);
    }
    items.push(link(max));
    if (next !== 0) {
        items.push(link(next, <>&raquo;</>));
    }
    return <nav className="pagenavi">
        {items.map((el, index) => {
            return <Fragment key={index}>{index !== 0 && <>&nbsp;</>}{el}</Fragment>;
        })}
    </nav>;
}

export default XwordsEntriesPageNavi;
