import React, { ChangeEvent, Component, FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import CosmoDbContext, { CosmoDbListData, CosmoDbListDataType } from './CosmoDbContext';
import CosmoDbUtils from './CosmoDbUtils';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    context: CosmoDbContext;
    list: CosmoDbListData;
    params: URLSearchParams;
}

interface State {
    pathname: string;
    listType: CosmoDbListDataType;
    sort: number;
    sort_method: 'asc' | 'desc'
    n: number;
    size: string;
}

class CosmoDbListSortBar extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = CosmoDbListSortBar.props2state(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeSort = this.handleChangeSort.bind(this);
        this.handleChangeSortMethod = this.handleChangeSortMethod.bind(this);
        this.handleChangeN = this.handleChangeN.bind(this);
        this.handleChangeSize = this.handleChangeSize.bind(this);
    }

    public static props2state(props: Props): State {
        const { location, list, params } = props;
        const paramSort = params.get('sort');
        const paramSortMethod = params.get('sort_method');
        const paramN = params.get('n');
        const size = CosmoDbUtils.getThumbnailSize(params.get('size'), list.thumb_size);
        return {
            pathname: location.pathname,
            listType: list.type,
            sort: paramSort !== null && /^\d+$/.test(paramSort) ? parseInt(paramSort) : 1,
            sort_method: paramSortMethod !== null && paramSortMethod === 'asc' ? 'asc' : 'desc',
            n: paramN !== null && /^\d+$/.test(paramN) ? parseInt(paramN) : 20,
            size: size !== null ? size.name : ''
        };
    }

    public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const pathname = nextProps.location.pathname;
        if (pathname !== prevState.pathname || nextProps.list.type !== prevState.listType) {
            return CosmoDbListSortBar.props2state(nextProps);
        }
        return null;
    }

    private handleSubmit(event: FormEvent<HTMLFormElement>) {
        const { context, list, params } = this.props;
        const nParams = new URLSearchParams(params);
        event.preventDefault();
        if (list.type === CosmoDbListDataType.Thumbnail) {
            nParams.set('size', this.state.size);
        }
        nParams.set('sort', this.state.sort.toString());
        nParams.set('sort_method', this.state.sort_method);
        nParams.set('n', this.state.n.toString());
        const url = context.getListUrl(nParams);
        this.props.history.push(url);
    }

    private handleChangeSort(event: ChangeEvent<HTMLSelectElement>) {
        const sort = parseInt(event.target.value);
        this.setState({ sort });
    }

    private handleChangeSortMethod(event: ChangeEvent<HTMLSelectElement>) {
        const sort_method = event.target.value as 'asc' | 'desc';
        this.setState({ sort_method });
    }

    private handleChangeN(event: ChangeEvent<HTMLSelectElement>) {
        const n = parseInt(event.target.value);
        this.setState({ n });
    }

    private handleChangeSize(event: ChangeEvent<HTMLSelectElement>) {
        const size = event.target.value;
        this.setState({ size });
    }

    public render() {
        const { lang, context, list } = this.props;
        const components = context.getComponents();
        const optionsSort: JSX.Element[] = [];
        components.forEach((value) => {
            if (value.onoff === 0) {
                optionsSort.push(<option key={value.comp_id} value={value.comp_id}>{Functions.mlang(value.tag, lang)}</option>);
            }
        });
        const optionsSize: JSX.Element[] = list.thumb_size.split(';').map((value) => {
            const key = value.split(',')[0];
            return <option key={key}>{key}</option>
        });
        return <form className="list-sortbar" onSubmit={this.handleSubmit}>
            <select value={this.state.sort} onChange={this.handleChangeSort}>
                {optionsSort}
            </select>
            <select value={this.state.sort_method} onChange={this.handleChangeSortMethod}>
                <option value="asc">{Functions.mlang('[en]ascending order[/en][ja]昇順[/ja]', lang)}</option>
                <option value="desc">{Functions.mlang('[en]descending order[/en][ja]降順[/ja]', lang)}</option>
            </select>
            <select value={this.state.n} onChange={this.handleChangeN}>
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
                <option value="100">100</option>
            </select>
            {list.type === CosmoDbListDataType.Thumbnail && <select value={this.state.size} onChange={this.handleChangeSize}>{optionsSize}</select>}
            <input className="formButton" type="submit" value="Sort" />
        </form>;
    }
}
export default withRouter(CosmoDbListSortBar);
