import axios from 'axios';
import loki from 'lokijs';

export interface PubmedPdfAuthorData {
    id: number;
    name: string;
    count: number;
}

export interface PubmedPdfJournalData {
    id: number;
    name: string;
    count: number;
}

export interface PubmedPdfPaperData {
    id: number;
    pmid: number;
    journal_id: number;
    year: number;
    volume: string;
    page: string;
    author_ids: number[];
    title: string;
}

export interface PubmedPdfYearData {
    year: number;
    count: number;
}

export interface PubmedPdfIndexData {
    name: string;
    author: PubmedPdfAuthorData[];
    journal: PubmedPdfJournalData[];
    paper: PubmedPdfPaperData[];
}

export interface PubmedPdfBreadcrumbsData {
    id: string;
    name: string;
    link: string
}

class PubmedPdfUtils {

    private isInitialized: boolean;
    private pathname: string;
    private database: loki;
    private name: string;
    private authors: Collection<PubmedPdfAuthorData>;
    private journals: Collection<PubmedPdfJournalData>;
    private papers: Collection<PubmedPdfPaperData>;
    private years: Collection<PubmedPdfYearData>;
    private authorInitials: string[];
    private journalInitials: string[];

    constructor(path: string) {
        this.isInitialized = false;
        this.pathname = path;
        this.database = new loki(path);
        this.name = '';
        this.authors = this.database.addCollection('authors');
        this.journals = this.database.addCollection('journals');
        this.papers = this.database.addCollection('papers');
        this.years = this.database.addCollection('years');;
        this.authorInitials = [];
        this.journalInitials = [];
    }

    getIndexUrl(): string {
        return this.pathname + '/index.php';
    }

    getAuthorUrl(id: number, year: number): string {
        return this.pathname + `/author.php?author=${id}` + (year !== 0 ? `&year=${year}` : '');
    }

    getAuthorInitialUrl(initial: string): string {
        return this.pathname + `/author.php?initial=${initial}`;
    }

    getYearUrl(year: number, journalId: number): string {
        return this.pathname + `/year.php?year=${year}` + (journalId !== 0 ? `&journal=${journalId}` : '');
    }

    getJournalUrl(journalId: number, year: number): string {
        return this.pathname + `/journal.php?journal=${journalId}` + (year !== 0 ? `&year=${year}` : '');
    }

    getPaperDescUrl(id: number): string {
        return this.pathname + `/paper_desc.php?id=${id}`;
    }

    getName(): string {
        return this.name;
    }

    getPaper(id: number): PubmedPdfPaperData | null {
        return this.papers.findOne({ id: id });
    }

    getAuthor(authorId: number): PubmedPdfAuthorData | null {
        return this.authors.findOne({ id: authorId });
    }

    getJournal(journalId: number): PubmedPdfJournalData | null {
        return this.journals.findOne({ id: journalId });
    }

    getAuthorInitials(): string[] {
        return this.authorInitials;
    }

    getJournalInitials(): string[] {
        return this.journalInitials;
    }

    getYears(): PubmedPdfYearData[] {
        return this.years.chain().find().simplesort('year').data();
    }

    getYearsByPapers(papers: PubmedPdfPaperData[]) {
        const count: { [year: number]: number } = {};
        papers.forEach((paper) => {
            if (typeof count[paper.year] === 'undefined') {
                count[paper.year] = 0;
            }
            count[paper.year]++;
        });
        const years = Object.keys(count).map((key) => parseInt(key));
        years.sort();
        return years.map((year) => {
            return { year: year, count: count[year] };
        });
    }

    getAuthors(initial?: string): PubmedPdfAuthorData[] {
        const filter: any = {};
        if (typeof initial !== 'undefined') {
            filter.name = { '$regex': [`^${initial}`, 'i'] };
        }
        return this.authors.chain().find(filter).simplesort('name').data();
    }

    getJournalsByInitial(initial: string): PubmedPdfJournalData[] {
        const filter = { name: { '$regex': [`^${initial}`, 'i'] } };
        return this.journals.chain().find(filter).simplesort('name').data();
    }

    getJournalsByYear(year: number): PubmedPdfJournalData[] {
        const filter = { year: year };
        const count: { [id: number]: number } = {};
        const papers = this.papers.find(filter);
        papers.forEach((paper) => {
            if (typeof count[paper.journal_id] === 'undefined') {
                count[paper.journal_id] = 0;
            }
            count[paper.journal_id]++;
        });
        return Object.keys(count).map((journalIdStr) => {
            const journalId = parseInt(journalIdStr);
            const journal = this.getJournal(journalId);
            return {
                id: journalId,
                name: journal !== null ? journal.name : '',
                count: count[journalId]
            };
        });
    }

    getPapersByAuthorId(authorId: number, year: number): PubmedPdfPaperData[] {
        const filter: any = { author_ids: { '$contains': authorId } };
        if (year !== 0) {
            filter.year = year;
        }
        return this.papers.chain().find(filter).simplesort('year').data();
    }

    getPapersByJournalId(journalId: number, year: number): PubmedPdfPaperData[] {
        const filter: any = { journal_id: journalId };
        if (year !== 0) {
            filter.year = year;
        }
        return this.papers.chain().find(filter).simplesort('year').data();
    }

    getCountPapers(): number {
        return this.papers.count();
    }

    async initialize(): Promise<boolean> {
        if (!this.isInitialized) {
            try {
                const url = this.pathname + '/index.json';
                const response = await axios.get(url);
                const index = response.data as PubmedPdfIndexData;
                this.name = index.name;
                index.author.forEach((value) => {
                    const initial = this.getInitial(value.name);
                    if (this.authorInitials.indexOf(initial) === -1) {
                        this.authorInitials.push(initial);
                    }
                    this.authors.insert(value);
                });
                this.authorInitials.sort();
                index.journal.forEach((value) => {
                    const initial = this.getInitial(value.name);
                    if (this.journalInitials.indexOf(initial) === -1) {
                        this.journalInitials.push(initial);
                    }
                    this.journals.insert(value);
                });
                this.journalInitials.sort();
                index.paper.forEach((value) => {
                    const year = this.years.findOne({ year: value.year });
                    if (year !== null) {
                        year.count++;
                        this.years.update(year);
                    } else {
                        this.years.insert({ year: value.year, count: 1 });
                    }
                    this.papers.insert(value);
                });
            } catch (err) {
                // ignore
            }
            this.isInitialized = true;
        }
        return this.isInitialized;
    }

    getInitial(name: string) {
        return name.charAt(0).toUpperCase();
    }

}

export default new PubmedPdfUtils('/modules/PubMedPDF');
